import React from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { breakPoints, mainColors } from '../../../constants/constants'
import { useViewport } from '../../../components/hooks/hooks'
import Page from '../../views/Page'
import { Col, Image } from 'react-bootstrap'
import ImagesCarousel from '../../../components/ImagesCarousel'

import dots from '../../../assets/home/dots.svg'
import abstractFrame from '../../../assets/home/abstract-frame.svg'

import accelLogo from '../../../assets/home/projects-accel-logo.png'
import { sharedContent } from '../../../components/CustomLinkButton'

const data = [
  {
    company_name: "company_name1",
    image: accelLogo
  },
]

const StyledSection = styled.section`
  background: ${mainColors.white};
  background-image: url(${dots});
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: ${breakPoints.sm}) {
    background-position: center right -500px;
    background-size: contain;
  }
`

const StyledPage = styled(Page)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (min-width: ${breakPoints.sm}) {
    background-image: url(${abstractFrame});
    background-repeat: no-repeat;
    background-position: bottom right 23%;
  }
`

const StyledDiv = styled.div`
  margin: 15% auto;

  h1 {
    font-size: 5em;
    line-height: 92px;
    color: ${mainColors.darkGray};
    font-weight: 700;

    span {
      color: ${mainColors.blue};
    }
  }

  P {
    padding-top: 27px;
  }

  a {
    display: inline-block;
    margin-left: 0 !important;
    margi-top: 25px;
  }

  @media (max-width: ${breakPoints.sm}) {
    margin: 20px auto;
    padding-bottom: 10px;
    h1 {
      font-size: 3em;
      line-height: 1;
    }
  }
`

const Logos = styled.div`
  background: ${mainColors.darkBlue};
  padding: 32px 0;
  margin-top: -2px;
  position: relative;

  .row {
    display: flex;
    justify-content: flex-start;
  }
`

const TitleCol = styled(Col)`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;

  h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: ${mainColors.white};
    white-space: nowrap;
  }

  @media (max-width: ${breakPoints.sm}) {
    justify-content: center;
    margin-bottom: 30px;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const StyledAnchor = styled.a`
  ${sharedContent}
`

const Hero = () => {
  const { t } = useTranslation()
  const { width } = useViewport()
  const breakpoint = parseInt(breakPoints.sm)
  const timer = 3000

  const items = () => (
    data.map((item, index) => (
      <ImageWrapper key={index}>
        <Image className="img-fluid" src={item.image} alt={items.company_name} />
      </ImageWrapper>
    ))
  )

  return (
    <StyledSection id="hero">
      <StyledPage>
        <Col>
          <StyledDiv>
            <h1>{t('home.hero.title_1')} <br /> <span>{t('home.hero.title_2')}</span></h1>
            <p>
              <Trans>{t('home.hero.subtitle')}</Trans>
            </p>
            <StyledAnchor
              href='#contact'
              $textColor={mainColors.white}
              $innerColor={mainColors.blue}
              $borderColor={mainColors.blue}
            >
              {t('mainMenu.contact')}
            </StyledAnchor>
          </StyledDiv>
        </Col>
      </StyledPage>
      {false && (
        <Logos>
          <Page>
            <TitleCol md={2} sm={12} xs={12}>
              <h3>{t('home.hero.carousel_title')}</h3>
            </TitleCol>
            <Col md={10} sm={12} xs={12}>
              <ImagesCarousel
                show={breakpoint > width ? 1 : 3}
                infiniteLoop
                autoScroll={breakpoint > width ? timer : 0}
                children={items()}
                renderPreviousButton={false}
                renderNextButton={false}
              />
            </Col>
          </Page>
        </Logos>
      )}
    </StyledSection>
  )
}

export default Hero
