import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { breakPoints, mainColors } from '../../../constants/constants'
import Page from '../../views/Page'
import { Tab } from 'bootstrap'
import { Tabs } from 'react-bootstrap'
import { useOnScreen } from '../../../components/hooks/hooks'

import blueCheck from '../../../assets/home/blue-check.svg'
import background from '../../../assets/home/samuel-regan-asante.png'

const data = {
  lend: [
    "badge_1",
    "badge_2",
    "badge_3",
    "badge_4",
    "badge_5",
    "badge_6"
  ],
  dev: [
    "badge_1",
    "badge_2",
    "badge_3",
    "badge_4",
    "badge_5",
    "badge_6"
  ]
}

const StyledSection = styled.section`
  background: ${mainColors.lightBlue};
  color: ${mainColors.darkGray};
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: ${breakPoints.sm}) {
    padding-top: 15%;
  }
`

const StyledPage = styled(Page)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 270px;

  #benefits {
    padding-bottom: 290px;

    @media (max-width: ${breakPoints.sm}) {
      padding-bottom: 0px;
    }
  }

  @media (max-width: ${breakPoints.sm}) {
    padding-top: 12%;
    padding-bottom: 12%;
  }

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    @media (max-width: ${breakPoints.sm}) {
      flex-direction: column;
    }

    button {
      padding: 0;
    }

    .nav-tabs {
      display: flex;
      flex-direction: column;
      border-bottom: none;
      justify-content: center;
      flex: 50%;
      border: none !important;

      @media (max-width: ${breakPoints.sm}) {
        margin-bottom: 40px !important;
      }
    }

    .tab-content {
      flex: 50%;
    }

    .nav-link {
      color: ${mainColors.darkBlue} !important;
      opacity: .5;
      font-weight: 500;
      font-size: 36px;
      line-height: 56px;
      border-color: transparent;
      background-color: transparent;
      transition: all .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;

      &.active {
        color: ${mainColors.blue} !important;
        font-weight: 700;
        font-size: 44px;
        line-height: 56px;
        display: block;
        position: relative;
        opacity: 1;
        background-color: transparent !important;
  
        &:after {
          content: '';
          position: absolute;
          width: 89px;
          height: 8px;
          left: 0;
          bottom: -4px;
          background: ${mainColors.darkBlue};
        }
      }
    }
  }
`

const Badge = styled.span`
  display: inline-block;
  background-color: ${mainColors.white};
  background-image: url(${blueCheck});
  background-repeat: no-repeat;
  background-size: 26px;
  background-position: left 17px center;
  padding: 8px 16px 8px 56px;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: 0.2px;
  color: ${mainColors.darkGray};
  border-radius: 8px;

  @media (max-width: ${breakPoints.sm}) {
    width: 100%;
  }
`

const BenefitsForLenders = () => {
  const { t } = useTranslation()
  const ref = useRef()

  const [theKey, setKey] = useState('lend')
  const keys = data[theKey]
  const onScreen = useOnScreen(ref, 1)

  useEffect(() => {
    if (onScreen) {
      setKey('dev')
    } else {
      setKey('lend')
    }
  }, [onScreen])

  const badges = () => (
    keys.map((item, index) => (
      <div key={index}>
        <Badge>{t('home.benefits.badges_' + theKey + '.' + item)}</Badge>
      </div>
    ))
  )

  return (
    <StyledSection ref={ref}>
      <StyledPage>
        <div id="benefits"></div>
        <Tabs
          id="benefits-tabs"
          activeKey={theKey}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="lend" title={t('home.benefits.title_lend')}>
            {badges()}
          </Tab>
          <Tab eventKey="dev" title={t('home.benefits.title_dev')}>
            {badges()}
          </Tab>
        </Tabs>

      </StyledPage>
    </StyledSection>
  )
}

export default BenefitsForLenders
