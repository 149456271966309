import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Uploady, { useBatchAddListener, useItemProgressListener, useBatchFinishListener, useBatchProgressListener, useRequestPreSend, useItemErrorListener } from '@rpldy/uploady'
import { createMockSender } from '@rpldy/mock-sender'
import UploadDropZone from '@rpldy/upload-drop-zone'
import UploadButton from '@rpldy/upload-button'
import { Line } from 'rc-progress'

import { ReactComponent as File } from '../assets/icons/file_text.svg'
import { ReactComponent as UploadFiles } from '../assets/upload_files.svg'
import { mainColors } from '../constants/constants'
import { Loading } from './sharedStyles'
import { closeModal } from '../redux/actions/modal-custom'

const UploadyWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .drag-over {
    outline: 2px dashed ${mainColors.neonBlue};
    outline-offset: -10px;
  }

  .upload-dropzone {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
  }

  .upload-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .upload-text {
      text-align: center;
      font-size: 16px;
      line-height: 19px;
      color: #A3AED0;
      margin: 32px 0;
    }
  
    .upload-button {
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #515058;
    }
  }

  .upload-progress {
    position: absolute;
    top: 50px;
    left: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    .upload-element {
      max-width: 100px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      svg {
        margin-top: -30px;
      }
    }

    p {
      font-size: 12px;
      margin: 0;
      text-align: center;
    }
  }
`

const StyledFile = styled(File)`
  width: 40px;
  height: 40px;
`

const StyledUploadButton = styled(UploadButton)`
  background: transparent;
  border: none;
  color: ${mainColors.neonBlue};
  text-decoration: underline;
`

const Error = styled.span`
  color: red;
  display: block;
`

const UploadProgress = () => {
  const [uploads, setUploads] = useState({})
  const progressData = useItemProgressListener()
  const [error, setError] = useState(null)

  if (progressData && progressData.completed) {
    const upload = uploads[progressData.id] ||
      { name: progressData.url || progressData.file.name, progress: [0] }

    if (!~upload.progress.indexOf(progressData.completed)) {
      upload.progress.push(progressData.completed)

      setUploads({
        ...uploads,
        [progressData.id]: upload,
      })
    }
  }

  const entries = Object.entries(uploads)

  useItemErrorListener((item) => {
    setError(item.uploadResponse.data.error)
  })

  return (
    <div className="upload-progress">
      {entries
        .map(([id, { progress, name }]) => {
          const lastProgress = progress[progress.length - 1]

          return (
            <div key={id} className="upload-element">
              <StyledFile />
              <div>
                <p>
                  {name}
                  {error ? (
                    <Error>{error}</Error>
                  ):(
                    <Line
                      strokeWidth={2}
                      strokeColor={lastProgress === 100 ? mainColors.neonBlue : mainColors.darkBlue}
                      percent={lastProgress}
                    />
                  )}
                </p>
              </div>
            </div>
          )
        })}
    </div>
  )
}

const UploadContainer = ({ setRedirectAfterUpload, items, subtext, params, params_key }) => {
  const dispatch = useDispatch()
  const [uploadState, setUploadState] = useState()
  const [uploadedItems, setUploadedItems] = useState(0)
  const [addedBatch, setAddedBatch] = useState([])
  
  useRequestPreSend(({ items }) => {
    const documentName = items[0].file.name

    return {
      options: {
        destination : {
          params: {[params_key]: JSON.stringify({
            ...params[params_key],
            document_name: documentName
          })}
        }
      }
    }
  })

  useBatchAddListener((batch) => {
    setAddedBatch(prevArray => [...prevArray, batch.id])
  })

  useBatchProgressListener((batch) => {
    setUploadState(batch?.state)
  })

  useBatchFinishListener((batch) => {
    setUploadState(batch?.state)
    setUploadedItems(uploadedItems + batch.items.length)

    const unfinishedDownloads = addedBatch
    const index = addedBatch.indexOf(batch.id)
    if (index !== -1) {
      unfinishedDownloads.splice(index, 1)
    }

    if (unfinishedDownloads.length === 0) {
      setRedirectAfterUpload(true)
      dispatch(closeModal())
    }

  })

  return (
    uploadState === 'added' ? (
      <div>
        <Loading />
        uploading
      </div>
    ) : (
      <div className="upload-container">
        <UploadFiles />
        <div className="upload-text">
          You have {uploadedItems} {items} uploaded.<br />
          {uploadState !== 'finished' && subtext}
        </div>
        <div className="upload-button">
          Drag and drop files or <StyledUploadButton>Click Here</StyledUploadButton> to upload
        </div>
      </div>
    )
  )
}

const DropZone = (
  props
) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return (
    <UploadyWrapper>
      <Uploady
        destination={{
          url: props.url,
        }}
        method={props.method}
        concurrent={false}
        grouped={false}
        clearPendingOnAdd
      >
        <UploadDropZone
          className="upload-dropzone"
          onDragOverClassName="drag-over"
        >
          <UploadProgress />
          <UploadContainer
            setRedirectAfterUpload={props.setRedirectAfterUpload}
            items={props.items}
            subtext={props.subtext}
            params={props.params}
            params_key={props.params_key}
          />
        </UploadDropZone>
      </Uploady>
    </UploadyWrapper>
  )
}

export default DropZone

DropZone.propTypes = {
  setRedirectAfterUpload: PropTypes.func,
  method: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  params_key: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

DropZone.defaultProps = {
  setRedirectAfterUpload: () => { }
}

//simulate upload functionality
const mockEnhancer = uploader => {
  const mockSender = createMockSender({ delay: 2000 })
  uploader.update({ send: mockSender.send })
  return uploader
}

