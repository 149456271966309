import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { StyledInput, StyledTextarea } from './sharedStyles'
import { breakPoints, mainColors } from '../constants/constants'
import CustomLinkButton from './CustomLinkButton'

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  input,
  textarea {
    min-width: 446px;
  }

  label {
    padding-top: 32px;
    padding-bottom: 8px;
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
  }

  textarea {
    margin-bottom: 36px;
    min-height: 151px;
  }

  @media (max-width: ${breakPoints.sm}) {
    input,
    textarea {
      min-width: 100%;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${breakPoints.sm}) {
    min-width: 100%;
  }
`

const ContactForm = () => {
  const { t } = useTranslation()

  const [status, setStatus] = useState(t('contact_form.submit'))
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus(t('contact_form.submitting'))
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Wrapper>
        <label htmlFor='name'>{t('contact_form.name')}</label>
        <StyledInput type="text" id="name" required />
      </Wrapper>
      <Wrapper>
        <label htmlFor='email'>{t('contact_form.email')}</label>
        <StyledInput type='email' id='email' required />
      </Wrapper>
      <Wrapper>
        <label htmlFor='message'>{t('contact_form.message')}</label>
        <StyledTextarea id="message" required />
      </Wrapper>
      <CustomLinkButton
        type="submit"
        textColor={mainColors.white}
        innerColor={mainColors.darkBlue}
        borderColor={mainColors.darkBlue}
        text={status}
        button
      />
    </StyledForm>
  )
}

export default ContactForm
