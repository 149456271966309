import * as type from '../types'

export const isAuth = (username, password) => (
  {
    type: type.AUTH_REQUESTED,
    username,
    password,
  }
)

export const logout_to = () => {
  localStorage.removeItem('state')
  return (
    { type: type.AUTH_MANUAL_LOGOUT }
  )
}
