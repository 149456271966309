import * as type from '../types'

export const openModal = (data) => (
  {
    type: type.OPEN_MODAL,
    data,
  }
)

export const closeModal = () => (
  {
    type: type.CLOSE_MODAL,
  }
)
