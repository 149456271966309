import * as type from '../types'

const initialState = {
  toggleSidebar: true,
}

export default function modal(state = initialState, action) {
  switch(action.type) {
    case type.TOGGLE_SIDEBAR:
        return {
            ...state,
            toggleSidebar: !state.toggleSidebar,
        }
    default:
      return state
  }
}
