import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'
import { breakPoints } from '../constants/constants'

export const sharedContent = css`
  text-decoration: none;
  display: inline-block;
  padding: 4px 40px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  border-color: ${({ $borderColor }) => $borderColor};
  color: ${({ $textColor }) => $textColor};
  background: ${({ $innerColor }) => $innerColor};

  &:hover {
    color: black;
  }

  @media (max-width: ${breakPoints.sm}) {
    display: flex;
    margin: 10px 0;
    align-items: center;
    justify-content: center;
  }

  &:last-child {
    @media (min-width: ${breakPoints.sm}) {
      margin-left: 20px;
    }
  }

  :disabled,
  [disabled]{
    opacity: .5;
  }
`

const StyledButton = styled.button`
  ${sharedContent}
`

const StyledNavLink = styled(NavLink)`
  ${sharedContent}
`
const CustomLinkButton = ({ ...props }) => {
  const { button, textColor, borderColor, innerColor, to, type, text, onClick, className, disabled } = props
  const Component = button ? (
    <StyledButton
      type={type}
      $textColor={textColor}
      $borderColor={borderColor}
      $innerColor={innerColor}
      onClick={onClick}
      className={className}
      disabled={disabled}
    >
      {text}
    </StyledButton>
  ) : (
    <StyledNavLink
      to={to}
      $textColor={textColor}
      $borderColor={borderColor}
      $innerColor={innerColor}
      className={className}
    >
      {text}
    </StyledNavLink>
  )
  return (
    Component
  )
}

CustomLinkButton.propTypes = {
  button: PropTypes.bool,
  text: PropTypes.string.isRequired,
  to: PropTypes.string,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
  innerColor: PropTypes.string,
  disabled: PropTypes.bool,
}

CustomLinkButton.defaultProps = {
  button: false,
  textColor: '#FFF',
  borderColor: '#FFF',
  innerColor: '#FFF',
  to: '/',
  disabled: false,
}

export default CustomLinkButton
