import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { dashboard, mainColors } from '../../constants/constants'

const ProjectWrapper = styled.div`
  background: ${mainColors.white};
  padding-left: ${dashboard.leftMargin};
  padding-right: ${dashboard.leftMargin};
  padding-bottom: 32px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`

const ProjectHeader = styled.div`
  padding-top: 25px;
  padding-bottom: 17px;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: ${mainColors.dashboardDarkBlue};
  display: flex;
  align-items: center;

  h2 {
    margin-right: 60px;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: ${(({ alignment }) => alignment)};
`

const ProjectLayout = ({ children, header, footer, footerAlignment, className }) => (
  <ProjectWrapper className={className}>
    {header && (
      <ProjectHeader>
        {header}
      </ProjectHeader>
    )}

    {children}
    {footer && (
      <ButtonsWrapper alignment={footerAlignment}>
        {footer}
      </ButtonsWrapper>
    )}
  </ProjectWrapper>
)

export default ProjectLayout

ProjectLayout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node,
  footer: PropTypes.node,
  footerAlignment: PropTypes.string,
}

ProjectLayout.defaultProps = {
  header: [],
  footer: [],
  footerAlignment: 'flex-start'
}
