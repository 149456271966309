import React, { useEffect, useState } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { breakPoints, mainColors, QUERY_KEYS } from '../constants/constants'
import { customSelect, Loading, sharedDashboardHeader } from '../components/sharedStyles'
import Select from 'react-select'
import { Bar, Line } from 'react-chartjs-2'
import PageMetaTitle from '../components/PageMetaTitle'
import DashLayout from './partials/DashLayout'
import LtcBadge from '../components/Ltc-badge'
import CustomTable from '../components/CustomTable'
import { useQuery } from 'react-query'
import { toggleActiveRow } from '../redux/actions/table'
import { getProjectsApi } from '../redux/sagas/projectsSaga'
import { userId } from '../components/helpers'
import CustomSelect from '../components/CustomSelect'

import { ReactComponent as ArrowUp } from '../assets/icons/arrow_drop_up.svg'
import { ReactComponent as Apart } from '../assets/icons/apart.svg'
import { ReactComponent as PFrame } from '../assets/icons/p_frame.svg'


const options_graph = [
  { value: 'property_1', label: 'This Year' },
  { value: 'property_2', label: 'This Month' },
]

const Header = styled.div`
  ${sharedDashboardHeader}
`

const MainSection = styled.div`
  margin-top: 30px;
`

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .select-wrapper,
  .badge-wrapper {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .badge-wrapper {
    justify-content: flex-end;

    .badge {
      background: #A3AED0;
      border-radius: 20px;
      color: #fff;
      position: relative;

      &:first-child {
        margin-right: 20px;
      }

      svg {
        position: absolute;
        left: 2px;
        top: 2px;
      }

      span {
        font-weight: 700;
        font-size: 20px;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        padding-left: 30px;
        padding-right: 15px;
      }
    }
  }
`

const ChartsWrapper = styled.div`
  .charts-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-title {
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    color: ${mainColors.dashboardDarkBlue};
    text-transform: capitalize;
  }

  .charts-body {
    padding-top: 17px;

    .row-two {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
      margin-bottom: 30px;

      @media (max-width: ${breakPoints.xs}) {
        grid-template-columns: 1fr;
        grid-auto-flow: row;
      }
    }

    .chart-wrapper {
      background: #FFFFFF;
      border-radius: 20px;
      padding: 24px;

      &.dohnut {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .chart-title-wrapper {
          text-align: left;
          width: 100%;
        }

        .chart-canvas {
          @media (max-width: ${breakPoints.md}) {
            max-width: 400px;
            max-height: 400px;
          }
          @media (max-width: ${breakPoints.sm}) {
            max-width: 211px;
            max-height: 211px;
          }
        }

        #legend-container {
          display: flex;
          justify-content: center;
        }

        ul {
          flex-wrap: wrap;
          max-width: 300px;
        }
      }

      &.bar {
        .chart-canvas {

        }
      }

      h3 {
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: ${mainColors.dashboardDarkBlue};
        text-transform: capitalize;
      }

      h5 {
        font-weight: 500;
        font-size: 14px;
        color: #6A7AAB;
        position: absolute;
      }

      &.bar {
        chart-title-wrapper {
          margin-bottom: 20px;
        }
      }

      &.multi-bar,
      &.line {
        .chart-title-wrapper {
          margin-bottom: -30px;

          h3,
          h5 {
            margin: 8px;
          }
        }
      }
    }
  }
`

const TableWrapper = styled.div`
  background: #FFFFFF;
  border-radius: 20px;
  padding: 24px;

  h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: ${mainColors.dashboardDarkBlue};
    text-transform: capitalize;
    margin-bottom: 25px;
  }
`

const Sales = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const toggle = useSelector(state => state.general.toggleSidebar)
  const data = useSelector(state => state.tables.sales_table.data)

  const HeaderInfo = (
    <Header>
      <h2>{t('sales.title')}</h2>
    </Header>
  )

  const [lineChartData, setLineChartData] = useState({})

  function getBackgroundGradient(ctx, chartArea) {
    let width, height, gradient;
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (gradient === null || width !== chartWidth || height !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
      gradient.addColorStop(.2, 'rgba(32, 129, 250, 0)');
      gradient.addColorStop(1, 'rgba(32, 129, 250, 0.3)');
    }

    return gradient;
  }

  function getBorderGradient(ctx, chartArea) {
    let width, height, gradient;
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (gradient === null || width !== chartWidth || height !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
      gradient.addColorStop(.1, 'rgba(32, 129, 250, 0.3)');
      gradient.addColorStop(1, 'rgba(32, 129, 250, 0.9)');
    }

    return gradient;
  }

  const lineChart = () => {
    setLineChartData({
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: [
        {
          data: [0, 0, 0, 0, 4, 7, 25, 55, 80, 0, 0, 0],
          borderColor: function (context) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
              // This case happens on initial chart load
              return null;
            }
            return getBorderGradient(ctx, chartArea);
          },
          pointBorderColor: 'transparent',
          pointBackgroundColor: 'darkblue',
          pointHoverBackgroundColor: 'transparent',
          pointHoverBorderColor: 'transparent',
          pointBorderWidth: 10,
          pointHoverRadius: 10,
          pointHoverBorderWidth: 0,
          pointRadius: 5,
          fill: true,
          backgroundColor: function (context) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
              // This case happens on initial chart load
              return null;
            }
            return getBackgroundGradient(ctx, chartArea);
          },
          borderWidth: 4,
        },
      ],
    })
  }

  const [multiBarChartData, setMultiBarChartData] = useState({})

  const multiBarChart = () => {
    setMultiBarChartData({
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: [
        {
          label: "Appartments",
          backgroundColor: "rgba(0, 35, 117, 0.6)",
          data: [0, 0, 0, 0, 4, 7, 25, 55, 80, 0, 0, 0],
          borderRadius: 12,
          hoverBackgroundColor: mainColors.dashboardDarkBlue,
          borderSkipped: false
        },
        {
          label: "Parking",
          backgroundColor: "rgba(0, 73, 255, 0.6)",
          data: [0, 0, 0, 0, 3, 4, 20, 55, 78, 0, 0, 0],
          borderRadius: 12,
          hoverBackgroundColor: "#0049FF",
          borderSkipped: false
        },
      ],
    })
  }

  useEffect(() => {
    lineChart()
    multiBarChart()
  }, [])

  const COLUMNS_APPROVED = [
    {
      Header: 'Building',
      Footer: 'Building',
      accessor: 'id',
      disableFilters: true,
      doNotHide: true,
      customWidth: '100px',
    },
    {
      Header: 'Floor',
      Footer: 'Floor',
      accessor: 'floor',
    },
    {
      Header: 'Apt. No.',
      Footer: 'Apt. No.',
      accessor: 'apt_no',
    },
    {
      Header: 'Surface (sqm)',
      Footer: 'Surface (sqm)',
      accessor: 'surface',
      componentType: 'inputNumber'
    },
    {
      Header: 'Balcony (sqm)',
      Footer: 'Balcony (sqm)',
      accessor: 'balcony',
      componentType: 'inputNumber'
    },
    {
      Header: 'Buyer',
      Footer: 'Buyer',
      accessor: 'buyer',
    },
    {
      Header: 'Contract Date',
      Footer: 'Contract Date',
      accessor: 'contract_date',
    },
    {
      Header: 'Contract Value',
      Footer: 'Contract Value',
      accessor: 'contract_value',
    },
    {
      Header: 'Amount Paid',
      Footer: 'Amount Paid',
      accessor: 'amount_paid',
    },
    {
      Header: 'Remaining to be paid',
      Footer: 'Remaining to be paid',
      accessor: 'remaining_to_be_paid',
    },
  ]

  const { data: options, isLoading: optionsLoading } = useQuery(
    [QUERY_KEYS.projects, userId()],
    getProjectsApi
  )

  return (
    <DashLayout Component={HeaderInfo}>
      <PageMetaTitle title={t('sales.title')} />
      <MainSection>
        <Top>
          <div className='select-wrapper'>
            <CustomSelect
              options={options?.hasOwnProperty('object_list') ? options : []}
              loading={optionsLoading}
            />
            <Select
              styles={customSelect}
              maxWidth='154px'
              defaultValue={{ value: 'property_1', label: 'This Year' }}
              options={options_graph}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => <ArrowUp />
              }}
            />
          </div>
          <div className='badge-wrapper'>
            <div className='badge'>
              <Apart />
              <span>200</span>
            </div>
            <div className='badge'>
              <PFrame />
              <span>180</span>
            </div>
          </div>
        </Top>
        <ChartsWrapper key={toggle}>
          <div className="charts-body">
            <div className="row-two">
              <div className="chart-wrapper line">
                <div className="chart-title-wrapper">
                  <h3>Total Pre-Sales</h3>
                  <h5>Value: $1,256,209</h5>
                </div>
                <div className="chart-canvas">
                  <Line
                    data={lineChartData}
                    type='line'
                    options={{
                      elements: {
                        line: {
                          tension: 0.5, // disables bezier curves
                        }
                      },
                      plugins: {
                        legend: {
                          display: false,
                        },
                        tooltip: {
                          enabled: true,
                          displayColors: false,
                          backgroundColor: mainColors.darkBlue,
                          caretSize: 0,
                          callbacks: {
                            label: function (tooltipItem) {
                              return tooltipItem.formattedValue + ' units'
                            },
                            title: function (tooltipItems, data) {
                              return '';
                            },
                          },
                        },
                      },
                      scales: {
                        x: {
                          grid: {
                            display: false,
                            drawBorder: false,
                          }
                        },
                        y: {
                          display: false,
                          grid: {
                            drawBorder: false,
                            display: false,
                          }
                        },
                      }
                    }}
                  />
                </div>
              </div>

              <div className="chart-wrapper multi-bar">
                <div className="chart-canvas">
                  <div className="chart-title-wrapper">
                    <h3>Total Units Contracted</h3>
                  </div>
                  <Bar
                    data={multiBarChartData}
                    type='line'
                    options={{
                      plugins: {
                        legend: {
                          display: true,
                          position: 'top',
                          align: 'end',
                          labels: {
                            boxWidth: 6,
                            boxHeight: 6,
                            usePointStyle: true,
                            pointStyle: 'circle'
                          }
                        },
                        tooltip: {
                          enabled: true,
                          displayColors: false,
                          backgroundColor: mainColors.darkBlue,
                          caretSize: 0,
                          xAlign: 'center',
                          yAlign: 'bottom',
                          callbacks: {
                            label: function (tooltipItem) {
                              return tooltipItem.formattedValue + ' units'
                            },
                            title: function (tooltipItems, data) {
                              return ''
                            },
                          },
                        },
                      },
                      scales: {
                        x: {
                          grid: {
                            display: false,
                            drawBorder: false,
                          }
                        },
                        y: {
                          display: false,
                          grid: {
                            drawBorder: false,
                            display: false,
                          }
                        },
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <TableWrapper>
            <h3>{t('sales.sales_tracker')}</h3>
            <CustomTable
              table_key='sales_table'
              table_data={data}
              cols={COLUMNS_APPROVED}
              showPagination={false}
              show_action_items={[]}
              showStandardColumns={false}
              toggleActiveRow={toggleActiveRow}
            />
          </TableWrapper>

        </ChartsWrapper>
      </MainSection>
    </DashLayout>
  )
}

export default Sales

