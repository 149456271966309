
import styled, { css } from 'styled-components'
import { breakPoints, dashboard, mainColors, TABLE_FONT_SIZE } from '../constants/constants'

import search from '../assets/icons/search.svg'

export const sharedInputTextarea = css`
  max-width: 328px;
  width: 100%;
  height: 45px;
  padding-left: 10px;
  border-radius: 8px;
  border: 1px solid transparent;

  &::placeholder {
    color: #B0B0B0;
  }
`

export const inputDashboard = css`
  border: 1px solid #A3AED0;
  border-radius: 10px;
  outline: none;
  max-width: 252px;
  width: 100%;
  position: relative;

  &:focus {
    border: 1px solid ${mainColors.neonBlue};
  }
`
export const StyledInput = styled.input`
  ${sharedInputTextarea}
`

export const StyledTextarea = styled.textarea`
  ${sharedInputTextarea}
`

export const StyledInputDashboard = styled.input`
  ${sharedInputTextarea}
  ${inputDashboard}
`

export const StyledSelect = styled.select`
  ${sharedInputTextarea}
  border: 1px solid #A3AED0;
  border-radius: 10px;
  outline: none;
  width: 252px;
  background: transparent !important;

  &:focus {
    border: 1px solid ${mainColors.neonBlue};
  }
`

export const sharedDashboardHeader = css`
  h2 {
    font-weight: 500;
    font-size: 34px;
    line-height: 42px;
    letter-spacing: -0.02em;
    color: ${mainColors.dashboardDarkBlue};
    margin: 0;
  }

  h4 {
    font-size: 14px;
    letter-spacing: -0.02em;
    color: #A3AED0;
    margin: 0;
  }
`

export const customSelect = {
  container: (provided, state) => ({
    ...provided,
    maxWidth: state.selectProps.maxWidth,
    width: '100%',
    height: '40px',
  }),
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #A3AED0',
    borderRadius: '4px',
    background: 'transparent'
  }),
  option: (provided, state) => ({
    ...provided,
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: mainColors.dashboardDarkBlue
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: mainColors.dashboardDarkBlue
  }),
}

export const customUsersSelect = {
  container: (provided, state) => ({
    ...provided,
    maxWidth: state.selectProps.maxWidth,
    width: '100%',
    position: 'absolute',
    left: '0',
    right: '0'
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    maxWidth: state.selectProps.maxWidth,
    padding: '0',
    maxHeight: '24px',
  }),
  input: (provided, state) => ({
    ...provided,
    maxWidth: state.selectProps.maxWidth,
    padding: '0',
    maxHeight: '24px',
    paddingLeft: '12px'
  }),
  control: (provided, state) => ({
    ...provided,
    border: 0,
    boxShadow: 'none',
    background: 'transparent',
    maxHeight: '24px',
    minHeight: 'auto'
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: TABLE_FONT_SIZE,
    lineHeight: '24px',
    color: '#515058'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: TABLE_FONT_SIZE,
    color: '#515058',
    paddingLeft: '12px'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    paddingLeft: '12px'
  }),
  menu: (provided, state) => ({
    ...provided,
    top: '36px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '0 0 8px 8px',
    padding: 0,
    border: 0,
    overflow: 'hidden'
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: 0
  }),
}

export const customCurrencySelect = {
  container: (provided, state) => ({
    ...provided,
    maxWidth: state.selectProps.maxWidth,
    width: '252px',
    height: '40px',
  }),
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #A3AED0;',
    borderRadius: '10px',
    background: 'transparent',
    minHeight: '44px'
  }),
  option: (provided, state) => ({
    ...provided,
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: mainColors.dashboardDarkBlue
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: mainColors.dashboardDarkBlue
  }),
}

export const DashBoardModalBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const DashBoardModalBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 36px;
  position: relative;

  @media (max-width: ${breakPoints.sm}) {
    min-width: 100%;

    input {
      width: 100%;
      max-width: inherit;
    }
  }

  label {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #515058;

    span {
      color: ${mainColors.neonBlue}
    }
  }
`

export const StyledSearchInput = styled.input`
  border-radius: 10px;
  border: 1px solid #A3AED0;
  font-size: 12px;
  height: 32px;
  max-width: 252px;
  width: 100%;
  padding-left: 42px;
  padding-right: 6px;
  background: url(${search}) no-repeat left 20px center;

  &:focus {
    outline: none;
    border-color ${mainColors.darkBlue};
  }

  &::placeholder {
    color: #8F9BBA;
  }
`

export const CustomCellWrapper = styled.div`
  position: relative;
  padding: 16px;
  font-size: ${TABLE_FONT_SIZE};
`

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  div {
    display: flex;
    align-items: center;
  }

  button {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: ${mainColors.darkGray};

    svg {
      margin-right: 10px;
    }
  }

  h2 {
    font-size: 24px;
  }
`

export const LinksWrapper = styled.div`
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    background-color: ${mainColors.white};
    padding-left: ${dashboard.leftMargin};
    margin-top: 25px;
    border-bottom: 1px solid #ECECEC;
    border-radius: 20px 20px 0px 0px;

    li {
      margin-right: 35px;

      a,
      span {
        text-decoration: none;
        font-size: 15px;
        line-height: 32px;
        color: #515058;
        display: block;
        padding-left: 6px;
        display: flex;
        align-items: center;
        padding: 20px 0;
        text-transform: capitalize;

        svg {
          margin-right: 8px;
        }

        &.active {
          font-weight: 700;
          border-bottom: 3px solid ${mainColors.blue};
          border-radius: 2px;
          color: ${mainColors.blue};
        }
      }
    }
  }
`

export const Loading = styled.div`
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`

export const StyledLoading = styled(Loading)`
  position: absolute;
  top: 50%;
  left: 50%;
`

export const Error = styled.div`
  position: absolute;
  bottom: -22px;
  left: 0;
  font-size: 12px;
  color: red;
`
