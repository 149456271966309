import React from 'react'
import { useField, useFormikContext } from 'formik'
import NumberFormat from 'react-number-format'
import { isObject } from 'lodash'
import { DashBoardModalBodyWrapper, Error, StyledInputDashboard } from './sharedStyles'

export const FormikInput = ({ label, ...props }) => {
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(props)
  const { value, name } = field

  const defaultValues = {
    number: ""
  }

  return (
    <DashBoardModalBodyWrapper key={props.id}>
      {label && (
        <label htmlFor={props.id || props.name}>
          {label}
          {props.showIcon && (
            <span>*</span>
          )}
        </label>
      )}

      {props.type === 'number' ? (
        <NumberFormat
          className="modal-number-input"
          name={name}
          placeholder={props.placeholder}
          thousandSeparator
          value={isObject(value) ? value.formattedValue : value}
          onValueChange={val =>
            setFieldValue(name, val.floatValue || defaultValues[name])
          }
          {...field}
        />
      ) : (
        <StyledInputDashboard className="text-input" {...field} {...props} />
      )}

      {meta.touched && meta.error ? (
        <Error className="error">{meta.error}</Error>
      ) : null}
    </DashBoardModalBodyWrapper>
  )
}
