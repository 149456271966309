import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { mainColors } from '../../constants/constants'
import { Accordion } from 'react-bootstrap'
import ProjectLayout from './ProjectLayout'
import CustomLinkButton from '../../components/CustomLinkButton'
import { HeaderWrapper } from '../../components/sharedStyles'
import Uploady, { useBatchAddListener, useItemProgressListener, useBatchFinishListener, useBatchProgressListener } from '@rpldy/uploady'
import UploadButton from '@rpldy/upload-button'
import DropZone from '../../components/DropZone'

import { ReactComponent as UndrawImageFolder } from '../../assets/undraw_image.svg'
import { ReactComponent as DeleteImage } from '../../assets/icons/trash.svg'


const DrawContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;

  .upload-text {
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    color: #A3AED0;
    margin: 32px 0;
  }

  .upload-button {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #515058;
  }
`

const AccordionWrapper = styled.div`
  .accordion {
    border-radius: 4px;
    margin-bottom: 16px;
    background: #FDFEFF;

    .upload-dropzone {
      height: 350px;
    }

    .accordion-button {
      font-weight: 500;
      font-size: 17px;
      color: #002375;
      background: #F4F7FE;
      box-shadow: none;

      &.collapsed {
        background: #FDFEFF;
      }

      span {
        padding-left: 5px;
      }
    }

    .accordion-item {
      border: 1px solid #F1F1F1;
    }

    .show,
    .collapsing {
      background: #F4F7FE;
    }
  }
`

const ImagesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(221px,1fr));
  grid-gap: 18px;

  .image-wrapper {
    border-radius: 9.24138px;
    overflow: hidden;
    border: 2px solid transparent;
    transition: all .1s ease-in;
    position: relative;

    img {
      height: 124px;
      object-fit: cover;
      width: 100%;
    }

    &:hover .delete-image-wrapper {
      display: block;
      cursor: pointer;
    }

    .delete-image-wrapper {
      display: none;
      position: absolute;
      top: 20px;
      right: 20px;
      background-color: ${mainColors.white};
    }

    &:hover {
      border: 2px solid ${mainColors.neonBlue};
      box-sizing: border-box;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    &-add {
      height: 124px;
      border-radius: 9.24138px;
      border: 1px solid #C2C9D1;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
`

const StyledUploadButton = styled(UploadButton)`
  background: transparent;
  border: none;
  color: #A3AED0;
`

const SitePictures = () => {
  const { t } = useTranslation()

  return (
    <ProjectLayout
      header={(
        <HeaderWrapper>
          <h2>{t('project.routes.site_pictures')}</h2>
        </HeaderWrapper>
      )}
      footer={
        <>
        {false && (
          <>
          <span />
          <span>
            <span>{t('project.site_pictures.drag_and_drop')}</span>
            <CustomLinkButton
              type="button"
              onClick={() => console.log('Upload images')}
              textColor={mainColors.white}
              innerColor={mainColors.neonBlue}
              borderColor={mainColors.neonBlue}
              text='Upload images'
              button
            />
          </span>
          </>
        )}
        </>
      }
      footerAlignment="space-between"
    >
      {false ? (
        <DrawContainer>
          <UndrawImageFolder />
          <div className="upload-text">
            You can’t upload images right now, because <br />
            you don’t have at least one draw.
          </div>
          <CustomLinkButton
            type="button"
            onClick={() => console.log('add budget line')}
            textColor={mainColors.white}
            innerColor={mainColors.neonBlue}
            borderColor={mainColors.neonBlue}
            text='Create Draw'
            button
          />
        </DrawContainer>
      ) : (
        <AccordionWrapper>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Draw 1 <span>(0 images)</span></Accordion.Header>
              <Accordion.Body>
                <DropZone />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="1">
              <Accordion.Header>Draw 2 <span>(7 images)</span></Accordion.Header>
              <Accordion.Body>
                <ImagesWrapper>
                  <div className="image-wrapper">
                    <img src="https://via.placeholder.com/728x1024.png?text=Visit+loany.com" />
                    <div className="delete-image-wrapper" onClick={() => console.log('delete image')}>
                      <DeleteImage />
                    </div>
                  </div>
                  <div className="image-wrapper">
                    <img src="https://via.placeholder.com/728x1024.png?text=Visit+loany.com" />
                    <div className="delete-image-wrapper" onClick={() => console.log('delete image')}>
                      <DeleteImage />
                    </div>
                  </div>
                  <div className="image-wrapper">
                    <img src="https://via.placeholder.com/728x1024.png?text=Visit+loany.com" />
                    <div className="delete-image-wrapper" onClick={() => console.log('delete image')}>
                      <DeleteImage />
                    </div>
                  </div>
                  <div className="image-wrapper">
                    <img src="https://via.placeholder.com/728x1024.png?text=Visit+loany.com" />
                    <div className="delete-image-wrapper" onClick={() => console.log('delete image')}>
                      <DeleteImage />
                    </div>
                  </div>
                  <div className="image-wrapper">
                    <img src="https://via.placeholder.com/728x1024.png?text=Visit+loany.com" />
                    <div className="delete-image-wrapper" onClick={() => console.log('delete image')}>
                      <DeleteImage />
                    </div>
                  </div>
                  <div className="image-wrapper">
                    <img src="https://via.placeholder.com/728x1024.png?text=Visit+loany.com" />
                    <div className="delete-image-wrapper" onClick={() => console.log('delete image')}>
                      <DeleteImage />
                    </div>
                  </div>
                  <div className="image-wrapper-add">
                    <Uploady
                      destination={{ url: "" }}
                      multiple={true}
                    >
                      <StyledUploadButton>Add new Image</StyledUploadButton>
                    </Uploady>
                  </div>
                </ImagesWrapper>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </AccordionWrapper>
      )}

    </ProjectLayout>
  )
}

export default SitePictures
