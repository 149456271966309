import { combineReducers } from 'redux'
import auth from './auth'
import authModal from './authModal'
import customModal from './customModal'
import projects from './projects'
import general from './general'
import tables from './tables'
import user from './user'

const appReducer = combineReducers({
  auth: auth,
  authModal: authModal,
  customModal: customModal,
  projects: projects,
  general: general,
  tables: tables,
  user: user,
})

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'AUTH_MANUAL_LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action)
}

export default rootReducer
