export const MOCK_DATA = {
  2: {
    ltc: {
      value: 30,
    },
    badges: [
      {
        0: '17,000,000 EUR',
      },
      {
        1: '12,900,000 EUR',
      },
      {
        2: '3,700,000 EUR',
      },
      {
        3: '1,500,000 EUR',
      }
    ],
    barChart: [0, 500000, 500000, 600000, 800000, 950000, 1400000, 550000, 300000, 0, 0, 0],
    doughnut: [4000000, 20000000, 3000000],
    lineChart: [0, 500000, 500000, 600000, 550000, 1250000, 500000, 500000, 300000, 0, 0, 0],
    multibarChart_one: [3, 7, 11, 2, 5, 2, 9, 7, 1, 4, 10, 2],
    multibarChart_two: [2, 8, 9, 4, 6, 8, 7, 7, 1, 4, 3, 4],
  },
  3: {
    ltc: {
      value: 50,
    },
    badges: [
      {
        0: '19,000,000 EUR',
      },
      {
        1: '11,700,000 EUR',
      },
      {
        2: '2,700,000 EUR',
      },
      {
        3: '1,900,000 EUR',
      }
    ],
    barChart: [0, 400000, 0, 700000, 600000, 950000, 100000, 550000, 200000, 0, 0, 200000],
    doughnut: [2000000, 1700000, 50000000],
    lineChart: [100000, 300000, 600000, 800000, 250000, 1250000, 500000, 500000, 500000, 0, 0, 0],
    multibarChart_one: [6, 3, 12, 4, 5, 2, 9, 7, 1, 4, 10, 2],
    multibarChart_two: [3, 2, 5, 4, 6, 8, 5, 7, 7, 4, 3, 1],
  },
  4: {
    ltc: {
      value: 75,
    },
    badges: [
      {
        0: '16,233,000 EUR',
      },
      {
        1: '13,155,000 EUR',
      },
      {
        2: '21,700,100 EUR',
      },
      {
        3: '1,200,599 EUR',
      }
    ],
    barChart: [0, 100000, 0, 500000, 300000, 950000, 100000, 550000, 200000, 0, 0, 200000],
    doughnut: [1000000, 1000000, 1000000],
    lineChart: [100000, 300000, 600000, 800000, 250000, 1250000, 500000, 500000, 500000, 0, 0, 0],
    multibarChart_one: [6, 3, 12, 4, 5, 2, 9, 7, 1, 4, 10, 2],
    multibarChart_two: [3, 2, 5, 4, 6, 8, 5, 7, 7, 4, 3, 1],
  },
}
