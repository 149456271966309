import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const Contact = () => {
  const { t } = useTranslation()

  return (
    <>
      <div>Contact</div>
    </>
  )
}

export default Contact
