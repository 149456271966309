import * as type from '../types'

export const getUserDetails = () => (
  {
    type: type.GET_USER_DETAILS_REQUESTED,
  }
)

export const updateUserDetails = (values) => (
  {
    type: type.UPDATE_USER_DETAILS_REQUESTED,
    values
  }
)
