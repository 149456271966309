export const urls = {
  home: "/",
  benefits: "/benefits",
  contact: "/contact",
  sign_in: "/sign-in",
  dashboard: "/dashboard",
  projects: "/projects",
  project: "/project/:projectId",
  sales: "/sales",
  account_settings: "/account-settings",
  super_user: "/super-user",
  about_us: "/about-us",
  terms: "/terms-and-conditions",
  privacy: "/privacy-policy",
  cookies: "/cookies-policy"
}

export const QUERY_KEYS = {
  projects: "projects",
  budget_lines: "budget_lines"
}

export const Role = {
  admin: 1,
  dev: 2,
  monitor: 3,  
}

export const headers = {
  'Accept': '*/*',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
  'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
  'Access-Control-Allow-Credentials': 'true',
}

export const TOKEN_TYPE = 'Bearer'

export const api = {
  base: 'http://ec2-3-65-91-230.eu-central-1.compute.amazonaws.com:8080',
  login: '/api/auth',
  users: '/api/users',
  read_user: '/readUser',
  projects_base: 'http://ec2-3-65-91-230.eu-central-1.compute.amazonaws.com:8081/api',
  projects: '/projects',
  budget_base: 'http://ec2-3-65-91-230.eu-central-1.compute.amazonaws.com:8082/api',
  budget_documents: '/documents',
  budget: '/budget',
  budget_categories: '/budget-categories',
  budget_category_update: '/update-budget-category',
  delete_budget_category: '/deleteBudgetCategory',
  contracts: '/contracts',
  invoices: '/invoices',
  calculus: '/calculus'
}

export const social = {
  "facebook": "https://www.facebook.com",
  "twitter": "https://www.twitter.com"
}

export const timer = {
  "automatic_logout_after": 5000000,
  "show_idle": 5000000
}

export const mainColors = {
  white: '#FFFFFF',
  darkBlue: '#002375',
  blue: '#2081FA',
  neonBlue: '#2081FA',
  lightBlue: '#F0F8FA',
  footerBlue: '#0363E0',
  darkGray: '#333D49',
  lighterGray: '#373F41',
  transparentGray: '#737B7D',
  lightBackground: '#FAFAFA',
  dashboardBlue: '#F4F7Fe',
  dashboardDarkBlue: '#1B2559'
}

export const menuColors = {
  gray: '#454D4F',
  neonBlue: '#2081FA',
  logoBarGray: '#DCDCDC',
  logoBarWhite: '#FFFFFF',
  logoTextGray: '#9D9D9D',
  logoTextWhite: '#FFFFFF',
}

export const sizes = {
  defaultContainer: 1400,
}

export const breakPoints = {
  xs: '768px',
  sm: '992px',
  md: '1200px',
}

export const dashboard = {
  leftMargin: '35px'
}

export const currencies = [
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
  { value: 'RON', label: 'RON' }
]

export const tableKeys = {
  budget_documents_table: 'budget_documents_table',
  budget_lines_table: 'budget_lines_table',
  budget_table: 'budget_table',
  budget_contracts_table: 'budget_contracts_table',
  budget_contracts_lines_table: 'budget_contracts_lines_table',
  budget_invoices_table: 'budget_invoices_table',
  budget_invoices_lines_table: 'budget_invoices_lines_table',
  invoices_table: 'invoices_table',
  budget_draw_manager_table: 'budget_draw_manager_table',
  users_monitor_table: 'users_monitor_table'
}

export const currencySymbols = {
  'USD': '$', // US Dollar
  'EUR': '€', // Euro
  'CRC': '₡', // Costa Rican Colón
  'GBP': '£', // British Pound Sterling
  'ILS': '₪', // Israeli New Sheqel
  'INR': '₹', // Indian Rupee
  'JPY': '¥', // Japanese Yen
  'KRW': '₩', // South Korean Won
  'NGN': '₦', // Nigerian Naira
  'PHP': '₱', // Philippine Peso
  'PLN': 'zł', // Polish Zloty
  'PYG': '₲', // Paraguayan Guarani
  'THB': '฿', // Thai Baht
  'UAH': '₴', // Ukrainian Hryvnia
  'VND': '₫', // Vietnamese Dong
  'RON': 'RON'
}

export const actionIcons = {
  edit: 'edit',
  delete: 'delete',
  ocr: 'ocr',
  download: 'download',
  reject: 'reject',
  approve: 'approve'
}

export const TABLE_FONT_SIZE = "14px"

