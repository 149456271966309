import React from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { breakPoints, mainColors } from '../../../constants/constants'
import Page from '../../views/Page'
import { Col } from 'react-bootstrap'

import quote from '../../../assets/home/quote-gray.svg'

const StyledSection = styled.section`
  @media (max-width: ${breakPoints.sm}) {
    padding-top: 15%;
    padding-bottom: 15%;
  }
`

const StyledPage = styled(Page)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 170px;
  padding-bottom: 170px;

  @media (max-width: ${breakPoints.sm}) {
    padding-top: 12%;
    padding-bottom: 12%;
  }

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    h2 {
      color: ${mainColors.darkGray};
      font-weight: 700;
      font-size: 44px;
      line-height: 56px;
      letter-spacing: 0.2px;
      display: block;
      position: relative;
      text-transform: uppercase;
    }

    p {
      color: ${mainColors.darkGray};
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.2px;
      padding: 16px 0;
      font-weight: 400;
    }
  }
`

const LeftCol = styled(Col)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;

  div {
    max-width: 85%;
    width: 100%;
  }

  @media (max-width: ${breakPoints.sm}) {
    div {
      max-width: 100%;
      width: 100%;
    }
  }
`

const RightCol = styled(Col)`
  font-style: italic;
  font-weight: 400;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: 0.2px;
  color: ${mainColors.neonBlue};
  position: relative;
  margin-top: 40px;
  padding: 0;
  display: flex;

  &:before {
    content: url(${quote});
    top: -55px;
    left: 6px;
    transform: scale(.5);
    margin-top: -20px;
  }

  &:after {
    content: url(${quote});
    top: -55px;
    left: 6px;
    transform: scale(.5);
    margin-top: -20px;
  }

  @media (max-width: ${breakPoints.sm}) {
    margin-top: 100px;
    width: 100%;
  }
`

const UnderHero = () => {
  const { t } = useTranslation()

  return (
    <StyledSection>
      <StyledPage>
        <LeftCol md={6}>
          <div>
            <h2>{t('about_us.under_hero.title')}</h2>
            <p><Trans>{t('about_us.under_hero.subtitle')}</Trans></p>
          </div>
        </LeftCol>
        <RightCol md={6}>
          {t('about_us.under_hero.quote')}
        </RightCol>
      </StyledPage>
    </StyledSection>
  )
}

export default UnderHero
