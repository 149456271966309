import React, { useState } from 'react'
import styled from 'styled-components'
import { Navigate, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { isAuth } from '../redux/actions/auth'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Page from './views/Page'
import { Col } from 'react-bootstrap'
import { breakPoints, mainColors, urls } from '../constants/constants'
import PageMetaTitle from '../components/PageMetaTitle'
import Logo from '../components/Logo'
import CustomLinkButton from '../components/CustomLinkButton'
import { Checkbox } from '../components/Checkbox'
import { FormikInput } from '../components/FormikInput'

const StyledPage = styled(Page)`
  margin-top: -160px;
  background: #F4F7FE;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;

  @media (max-width: ${breakPoints.xs}) {
    .col {
      padding: 0;
    }
    .row {
      width: 100%;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    margin-left: 0 !important;
    width: 100%;
  }
`

const LoginWrapper = styled.div`
  background: ${mainColors.white};
  min-width: 540px;
  width: 100%;
  padding: 40px 95px;
  margin-top: 50px;

  @media (max-width: ${breakPoints.xs}) {
    min-width: 200px;
    padding: 10px 10px;
    margin-top: 25px;
  }

  h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 42px;
    color: ${mainColors.dashboardDarkBlue};
    margin-bottom: 32px;
    text-align: center;
  }

  h5 {
    text-align: center;
    font-size: 14px;
    color: ${mainColors.dashboardDarkBlue};
  }

  input {
    max-width: 350px;

    &::placeholder {
      font-size: 15px;
    }

    @media (max-width: ${breakPoints.xs}) {
      max-width: inherit;
    }
  }

  .error {
    position: absolute;
    bottom: -22px;
    left: 0;
    font-size: 12px;
    color: red;
  }
`

const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 11px;

  a {
    display: block;
    font-size: 12px;
    color: ${mainColors.darkGray};
    text-decoration: none;
  }
`

const JoinNow = styled.div`
  margin-top: 32px;
  font-size: 16px;
  line-height: 18px;
  color: #616161;
  text-align: center;

  a {
    color: ${mainColors.neonBlue};
    padding-left: 5px;
  }
`

const RememberMe = styled.span`
  display: flex;
  align-items: center;

  label span {
    font-size: 12px;
    color: #333D49;
    opacity: 1;
  }
`

const SignIn = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const tempHidden = true
  const auth = useSelector(state => state.auth.isAuth)
  const loading = useSelector(state => state.auth.loading)
  const authError = useSelector(state => state.auth.error)

  const [check, setCheck] = useState(false)
  return (
    <StyledPage>
      <PageMetaTitle title={t('mainMenu.signIn')} />
      <Col>
        {auth ? (
          <Navigate to={urls.dashboard} />
        ) : (
          <Wrapper>
            <Logo
              color="blue"
              textColor="gray"
              separatorColor="gray"
            />
            <LoginWrapper>
              <h2>{t('signIn.title')}</h2>
              {authError && <h5>{t('signIn.auth_error')}</h5>}
              <Formik
                initialValues={{
                  email: "",
                  password: ""
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .max(15, "Must be 15 characters or less")
                    .required(t('validations.required')),
                  password: Yup.string()
                    .max(20, "Must be 20 characters or less")
                    .required(t('validations.required'))
                })}
                onSubmit={async (values) => {
                  const { email, password } = values
                  dispatch(isAuth(email, password))
                }}
              >
                <Form>
                  <FormikInput
                    label={t('signIn.email')}
                    name="email"
                    type="text"
                    placeholder={t('signIn.email_placeholder')}
                  />
                  <FormikInput
                    label={t('signIn.password')}
                    name="password"
                    type="password"
                    placeholder={t('signIn.password_placeholder')}
                  />
                  <CustomLinkButton
                    textColor={mainColors.white}
                    innerColor={mainColors.neonBlue}
                    borderColor={mainColors.neonBlue}
                    text={loading ? 'loading...' : t('signIn.button')}
                    type='submit'
                    button
                  />
                </Form>
              </Formik>
              {!tempHidden && (
                <LinkWrapper>
                  <RememberMe>
                    <Checkbox
                      id="remember-me"
                      defaultChecked={check}
                      onChange={() => setCheck(!check)}
                      label={t('signIn.remember_me')}
                    />
                  </RememberMe>
                  <NavLink to="/">{t('signIn.forgot_password')}</NavLink>
                </LinkWrapper>
              )}
            </LoginWrapper>
            {!tempHidden && (
              <JoinNow>
                {t('signIn.no_account')}
                <NavLink to="/">{t('signIn.join')}</NavLink>
              </JoinNow>
            )}
          </Wrapper>
        )}
      </Col>
    </StyledPage>
  )
}

export default SignIn
