import React from 'react'
import styled from 'styled-components'
import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import PageMetaTitle from '../components/PageMetaTitle'
import MainLayout from './partials/MainLayout'
import Page from './views/Page'
import { mainColors } from '../constants/constants'
import CustomLinkButton from '../components/CustomLinkButton'

const textColor = '#333D49'

const StyledSection = styled.section`
  padding-top: 70px;
  padding-bottom: 100px;

  h1 {
    font-weight: 700;
    font-size: 44px;
    line-height: 56px;
    color: ${textColor};
  }

  h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 56px;
    letter-spacing: 0.2px;
    color: ${mainColors.neonBlue};
  }

  h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.2px;
    color: ${textColor};
  }

  p {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.2px;
    color: ${textColor};
  }

  button {
    height: 44px;
    width: 208px;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const HeaderDate = styled.div`

`

const Terms = () => {
  const { t } = useTranslation()

  return (
    <MainLayout>
      <PageMetaTitle title={t('footerMenu.terms')} />
      <StyledSection>
        <Page>
          <Col md={12}>
            <Header>
              <h1>
                Terms and Conditions
              </h1>
              <CustomLinkButton
                textColor={mainColors.white}
                innerColor={mainColors.darkBlue}
                borderColor={mainColors.darkBlue}
                text='Print'
                type='button'
                button
              />
            </Header>
            <HeaderDate>
              Updated Sep 15, 2020
            </HeaderDate>
            <hr />
          </Col>
          <Col md={12}>
            <section>
              <h3>
                Please read our policy carefully
              </h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              </p>
            </section>
            <section>
              <h3>
                What personal data we collect and why we collect it
              </h3>
              <span>
                <h4>
                  Media
                </h4>
                <p>
                  If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website.
                </p>
              </span>
              <span>
                <h4>
                  Cookies
                </h4>
                <p>
                  If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.
                </p>
                <p>
                  If you have an account and you log in to this site, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.
                </p>
                <p>
                  When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember Me”, your login will persist for two weeks. If you log out of your account, the login cookies will be removed.
                </p>
                <p>
                  If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.
                </p>
              </span>
              <span>
                <h4>
                  Embedded content from other websites.
                </h4>
                <p>
                  Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.
                </p>
                <p>
                  These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.
                </p>
              </span>
            </section>
          </Col>
        </Page>
      </StyledSection>
    </MainLayout>
  )
}

export default Terms
