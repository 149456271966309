import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { breakPoints, mainColors } from '../../../constants/constants'
import Page from '../../views/Page'
import { Col } from 'react-bootstrap'
import StyledCarousel from '../../../components/Carousel'

import noise from '../../../assets/home/noise.png'
import rectangle from '../../../assets/home/rectangle.png'

import { ReactComponent as Cube } from '../../../assets/home/cube.svg'


const StyledSection = styled.section`
  background: ${mainColors.lightBackground};
  padding-top: 140px;
  padding-bottom: 100px;
  position: relative;
`

const TitleCol = styled(Col)`
  text-align: center;

  h2 {
    font-weight: bold;
    font-size: 44px;
    line-height: 56px;
    color: ${mainColors.lighterGray};
  }
`

const WrapperCol = styled.div`
  margin-top: 98px;
  margin-bottom: 50px;
  display: flex;
  align-content: center;
  justify-content: center;
  position: relative;
`

const CarouselWrapper = styled(Col)`
  background-image: url(${noise}), url(${rectangle});
  background-position: center, center;
  background-repeat: no-repeat, no-repeat;
  display: flex;
  align-content: center;
  justify-content: center;
  position: relative;
  height: 100%;
  max-width: 917px;
  width: 100%;
`

const LeftCube = styled(Cube)`
  position: absolute;
  bottom: -56px;
  left: 34px;
  scale: .7;

  @media (max-width: ${breakPoints.sm}) {
    display: none;
  }
`

const RightCube = styled(Cube)`
  position: absolute;
  top: 90px;
  right: 34px;

  @media (max-width: ${breakPoints.sm}) {
    display: none;
  }
`

const Testimonials = () => {
  const { t } = useTranslation()

  return (
    <StyledSection>
      <Page>
        <TitleCol md={12}>
          <h2>{t('home.testimonials.title')}</h2>
        </TitleCol>
        <WrapperCol>
          <LeftCube />
          <RightCube />
          <CarouselWrapper md={8} sm={12} xs={12}>
            <StyledCarousel />
          </CarouselWrapper>
        </WrapperCol>
      </Page>
    </StyledSection>
  )
}

export default Testimonials
