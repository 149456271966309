import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout_to } from '../../redux/actions/auth'
import { breakPoints, mainColors, menuColors, urls, social } from '../../constants/constants'
import { Navbar, Nav } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Scrollspy from 'react-scrollspy'

import Page from '../views/Page'
import Logo from '../../components/Logo'
import CustomLinkButton from '../../components/CustomLinkButton'
import LanguageButtons from '../../components/LanguageButtons'

import { ReactComponent as Facebook } from '../../assets/LogoFacebook-Gray.svg'
import { ReactComponent as Linkedin } from '../../assets/LogoLink-Gray.svg'

const socialItems = [
  {
    href: social.facebook,
    icon: <Facebook />
  },
  {
    href: social.twitter,
    icon: <Linkedin />
  }
]

const mainLinks = [
  {
    to: '/#our-product',
    title: 'mainMenu.home',
    type: 'anchor'
  },
  {
    to: '/#benefits',
    title: 'mainMenu.benefits',
    type: 'anchor'
  },
  {
    to: '/#contact',
    title: 'mainMenu.contact',
    type: 'anchor'
  },
  {
    to: urls.about_us,
    title: 'mainMenu.about_us',
    type: 'navlink'
  },
]

const buttonsLinks = [
  {
    textColor: mainColors.darkBlue,
    innerColor: mainColors.white,
    borderColor: mainColors.darkBlue,
    to: urls.sign_in,
    text: 'mainMenu.signIn',
  },
  {
    textColor: mainColors.white,
    innerColor: mainColors.blue,
    borderColor: mainColors.blue,
    to: urls.contact,
    text: 'mainMenu.contact',
  }
]

const StyledWrapper = styled.header`
  width: 100%;
  background: ${mainColors.white};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  z-index: 10;
  position: fixed;
  top: 0;
`

const StyledNavbar = styled(Navbar)`
  .buttons {
    a:first-child {
      margin-right: 30px;
      display: block;
    }

    @media (max-width: ${breakPoints.sm}) {
      a {
        padding-bottom: 12px;
        padding-left: 12px;
      }
    }
  }

  .menu {
    justify-content: center;
    flex: 1;

    ul {
      display: flex;
    }
  }
`

const StyledLink = styled(NavLink)`
  font-size: 15px;
  line-height: 36px;
  color: ${menuColors.gray};
  font-weight: 400;
  text-decoration: none;
  text-transform: cammelcase;
  padding: 11px 16px;
  position: relative;

  &:before {
    display: block;
    content: attr(title);
    font-weight: 500;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  &.active,
  &:hover {
    color: ${menuColors.gray};
    font-weight: 500;
    @media (min-width: ${breakPoints.sm}) {
      &:after {
        content: '';
        height: 8px;
        width: 8px;
        background-color: ${menuColors.neonBlue};
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        bottom: 4px;
        left: calc(50% - 4px);
      }
    }
  }
`

const StyledAnchor = styled.a`
  font-size: 15px;
  line-height: 36px;
  color: ${menuColors.gray};
  font-weight: 400;
  text-decoration: none;
  text-transform: cammelcase;
  padding: 11px 16px;
  position: relative;

  &:before {
    display: block;
    content: attr(title);
    font-weight: 500;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  &.active,
  &:hover {
    color: ${menuColors.gray};
    font-weight: 500;
    @media (min-width: ${breakPoints.sm}) {
      &:after {
        content: '';
        height: 8px;
        width: 8px;
        background-color: ${menuColors.neonBlue};
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        bottom: 4px;
        left: calc(50% - 4px);
      }
    }
  }
`

const Header = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const logout = () => { dispatch(logout_to()) }
  const auth = useSelector(state => state.auth.isAuth)

  const links = mainLinks.map(link => {
    if (link.type === 'anchor') {
      return <StyledAnchor key={t(link.title)} href={link.to}>{t(link.title)}</StyledAnchor>
    }
    return (
      <StyledLink key={t(link.title)} to={link.to} title={t(link.title)}>{t(link.title)}</StyledLink>
    )
  })

  const buttons = buttonsLinks.map(button => (
    <CustomLinkButton
      key={t(button.text)}
      textColor={button.textColor}
      innerColor={button.innerColor}
      borderColor={button.borderColor}
      to={button.to}
      text={t(button.text)}
    />
  ))

  const items = (data) => data.map((item, index) => (
    <a key={index} href={item.href}>
      {item.icon}
    </a>
  ))

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <StyledWrapper>
      <Page>
        <StyledNavbar expand="lg">
          <Logo
            color="blue"
            onClick={() => scrollToTop()}
          />
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto menu">
              <Scrollspy
                items={['our-product', 'benefits', 'contact']}
                currentClassName='active'
                offset={1}
              >
                {links}
              </Scrollspy>
            </Nav>
            <Nav className="mr-auto buttons">
              {items(socialItems)}
              {/*!auth ? (
                buttons
              ):(
              <>
                <LanguageButtons />
                <CustomLinkButton
                  type="button"
                  onClick={logout}
                  textColor={mainColors.darkBlue}
                  innerColor={mainColors.white}
                  borderColor={mainColors.darkBlue}
                  text='Logout'
                />
              </>
              )*/}
            </Nav>
          </Navbar.Collapse>
        </StyledNavbar>
      </Page>
    </StyledWrapper>
  )
}

export default Header

