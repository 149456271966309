import React, { useState, useEffect, useRef, forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PageMetaTitle from '../components/PageMetaTitle'
import DashLayout from './partials/DashLayout'
import { CustomCellWrapper, HeaderWrapper, LinksWrapper, sharedDashboardHeader, StyledLoading, StyledSearchInput } from '../components/sharedStyles'
import ProjectLayout from './partials/ProjectLayout'
import { actionIcons, mainColors, tableKeys, QUERY_KEYS } from '../constants/constants'
import CustomLinkButton from '../components/CustomLinkButton'
import CustomTabs from '../components/CustomTabs'
import CustomTable, { defaultColumns } from '../components/CustomTable'
import { fetchTableData, toggleActiveRow } from '../redux/actions/table'
import CustomTableFilters from '../components/CustomTableFilters'
import { useQuery } from 'react-query'
import { getProjectsApi } from '../redux/sagas/projectsSaga'
import { userId } from '../components/helpers'

const Header = styled.div`
  ${sharedDashboardHeader}
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledLinksWrapper = styled(LinksWrapper)`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  ul {
    padding: 0;
    margin: 0;

    li {
      margin: 0;
    }
  }
`

const StyledProjectLayout = styled(ProjectLayout)`
  margin-top: 25px;
  border-radius: 20px 20px 0px 0px;
`

const CustomCell = styled.span`
  padding: 1px 8px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 10px;

  ${({ $color }) => $color === 'Ongoing' && css`
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2081FA;
    border: 1px solid #2081FA;
    color: #2081FA;
  `}

  ${({ $color }) => $color === 'Ended' && css`
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #002375;
    border: 1px solid #002375;
    color: #002375;
  `}
`

const GenericTabComponent = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const activeRowId = useSelector(state => state.tables.users_monitor_table.active_row)
  const loading = useSelector(state => state.tables.users_monitor_table.loading)
  const tableData = useSelector(state => state.tables.users_monitor_table.data)

  useEffect(() => {
    dispatch(fetchTableData(tableKeys.users_monitor_table))
  }, [dispatch])

  const { data: options } = useQuery(
    [QUERY_KEYS.projects, userId()],
    getProjectsApi
  )

  const COLUMNS = [
    {
      Header: t('super_user.users_monitor_table.email'),
      accessor: 'email',
      componentType: 'email'
    },
    {
      Header: t('super_user.users_monitor_table.project'),
      accessor: 'project',
      componentType: 'isSelect',
      selectOptions: options?.object_list,
    },
    {
      Header: t('super_user.users_monitor_table.status'),
      accessor: 'status',
      doNotHide: true,

      Cell: (tableProps) => (
        <CustomCellWrapper>
          <CustomCell $color={tableProps.value}>
            {tableProps.value}
          </CustomCell>
        </CustomCellWrapper>
      ),
    },
  ]

  const toggleTableHeaders = useSelector(state => state.tables.users_monitor_table)
  const [outsideFilter, setOutsideFilter] = useState('')
  const ALL_COLUMNS = COLUMNS.concat(defaultColumns())

  return (
    <>
      {loading ? (
        <StyledLoading />
      ) : (
        <>
          {false && (
            <HeaderWrapper>
              <div>
                <StyledSearchInput
                  type="text"
                  id="title"
                  required
                  placeholder='Search'
                  value={outsideFilter || ''}
                  onChange={e => {
                    setOutsideFilter(e.target.value);
                  }}
                />
              </div>
              <CustomTableFilters
                columns={ALL_COLUMNS}
                headers={toggleTableHeaders.headers}
                table_key={tableKeys.users_monitor_table}
              />
            </HeaderWrapper>
          )}
          <CustomTable
            table_key={tableKeys.users_monitor_table}
            table_data={tableData}
            cols={COLUMNS}
            show_action_items={[actionIcons.edit, actionIcons.delete]}
            showPagination
            activeRowId={activeRowId}
            toggleActiveRow={toggleActiveRow}
            outsideFilter={outsideFilter}
            hideHeaders={toggleTableHeaders.hide_headers}
            ref={ref}
          />
        </>
      )}
    </>
  )
})

const SuperUser = () => {
  const { t } = useTranslation()
  const addUserRef = useRef()
  const activeRowIdUserLines = useSelector(state => state.tables.users_monitor_table.active_row)

  const HeaderInfo = (
    <Header>
      <h2>{t('super_user.user_management')}</h2>
    </Header>
  )

  const tabs = [
    {
      eventKey: 'monitor',
      title: t('super_user.monitor'),
      Component: <GenericTabComponent ref={addUserRef} userRole='monitor' />
    },
    {
      eventKey: 'developer',
      title: t('super_user.developer'),
      Component: <GenericTabComponent ref={addUserRef} userRole='developer' />
    },
    {
      eventKey: 'bank',
      title: t('super_user.bank'),
      Component: <GenericTabComponent ref={addUserRef} userRole='bank' />
    }
  ]

  return (
    <DashLayout Component={HeaderInfo}>
      <PageMetaTitle title={t('super_user.user_management')} />
      <StyledProjectLayout
        header={(
          <HeaderWrapper>
            <h2>{t('super_user.users')}</h2>
          </HeaderWrapper>
        )}
        footer={
          <CustomLinkButton
            type="button"
            textColor={mainColors.white}
            innerColor={mainColors.neonBlue}
            borderColor={mainColors.neonBlue}
            text={t('super_user.add_new_user')}
            button
            onClick={() => addUserRef.current.onAddLine()}
            disabled={activeRowIdUserLines ? true : false}
          />
        }
        footerAlignment="flex-end"
      >
        <StyledLinksWrapper>
          <CustomTabs
            id="super-user-tabs"
            tabs={tabs}
            defaultKey='monitor'
          />
        </StyledLinksWrapper>
      </StyledProjectLayout>
    </DashLayout>
  )
}

export default SuperUser

