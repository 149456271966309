import { convertToInternationalCurrencySystem } from "../helpers"

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id)

  let listContainer = legendContainer.querySelector('ul')

  if (!listContainer) {
    listContainer = document.createElement('ul')
    listContainer.style.display = 'flex'
    listContainer.style.flexDirection = 'row'
    listContainer.style.justifyContent = 'space-between'
    listContainer.style.marginTop = '33px'
    listContainer.style.padding = 0

    legendContainer.appendChild(listContainer)
  }

  return listContainer;
}

export const htmlLegendPluginDohnut = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID)

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove()
    }
    
    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart)

    if (!items) return false

    items.forEach((item, i) => {
      const li = document.createElement('li')
      li.style.alignItems = 'center'
      li.style.cursor = 'pointer'

      li.style.marginLeft = '10px'
      li.style.listStyleType = 'none'

      li.onclick = () => {
        const { type } = chart.config;
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index)
        } else {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex))
        }
        chart.update()
      };

      // Color box
      const circleElem = document.createElement('div')
      circleElem.style.background = item.fillStyle
      circleElem.style.borderColor = item.strokeStyle
      circleElem.style.borderWidth = item.lineWidth + 'px'
      circleElem.style.display = 'inline-block'
      circleElem.style.height = '8px'
      circleElem.style.marginRight = '12px'
      circleElem.style.width = '8px'
      circleElem.style.borderRadius = '4px'
      circleElem.style.opacity = '.5'
      circleElem.style.border = '1px solid' + item.fillStyle

      // Text
      const textContainer = document.createElement('div')
      textContainer.style.color = 'rgba(0, 0, 0, 0.38)'
      textContainer.style.margin = 0
      textContainer.style.padding = 0
      textContainer.style.textDecoration = item.hidden ? 'line-through' : ''
      textContainer.style.fontSize = '12px'

      const text = document.createTextNode(item.text)
      textContainer.appendChild(text)

      //Value
      const valueContainer = document.createElement('div')
      valueContainer.style.color = item.fontColor
      valueContainer.style.fontSize = '16px'
      valueContainer.style.textAlign = 'right'
      valueContainer.style.textDecoration = item.hidden ? 'line-through' : ''
      
      const value = document.createTextNode('€' + convertToInternationalCurrencySystem(chart._metasets[0]._parsed[i]))
      valueContainer.appendChild(value)

      // Wrapper
      const wrapperElem = document.createElement('div')
      wrapperElem.style.display = 'flex'
      wrapperElem.style.alignItems = 'center'

      wrapperElem.appendChild(circleElem)
      wrapperElem.appendChild(textContainer)
      li.appendChild(wrapperElem)
      li.appendChild(valueContainer)
      ul.appendChild(li)
    })
  }
}
