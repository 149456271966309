import * as type from '../types'

export const getProjects = (data) => (
  {
    type: type.GET_PROJECTS_REQUESTED,
    data,
  }
)

export const createProject = (values) => (
  {
    type: type.CREATE_PROJECT_REQUESTED,
    values
  }
)

export const getProjectDetails = (title) => (
  {
    type: type.GET_PROJECT_DETAILS_REQUESTED,
    title,
  }
)
