import { all, call, put, takeLatest } from 'redux-saga/effects'
import { localToken, userId } from '../../components/helpers'
import { api, headers, TOKEN_TYPE } from '../../constants/constants'
import { getUserDetails } from '../actions/user'

function fetchUserDetailsApi() {
  return fetch(api.base + api.users + api.read_user + '?userId=' + userId(), {
    method: 'GET',
    headers: {
      ...headers,
      'Authorization': TOKEN_TYPE + ' ' + localToken()
    },
  }).then(response => response.json())
    .catch((error) => {throw error})
}

function updateUserDetailsApi(values) {
  //should be updated accordingly, missing pass, role_id from userDetails object
  return fetch(api.base + api.users, {
    method: 'PUT',
    headers: {
      ...headers,
      'Authorization': TOKEN_TYPE + ' ' + localToken()
    },
    body: JSON.stringify({ 
      "email": values.email,
      "password": "test",
      "role_id": values.role_id,
      "status": values.status,
      "user_id": userId(),
      "user_name": values.name
    })
  }).then(response => response.json())
    .catch((error) => {throw error})
}

function* fetchUserDetails() {
  try {
    const details = yield call(() => fetchUserDetailsApi())

    yield put({ type: 'GET_USER_DETAILS_SUCCESS', userDetails: details })
  } catch(e) {
    yield put({ type: 'GET_USER_DETAILS_FAILED', message: e.message })
  }
}

function* updateUserDetails({ values }) {
  try {
    const details = yield call(() => updateUserDetailsApi(values))

    yield put({ type: 'UPDATE_USER_DETAILS_SUCCESS', userDetails: details })
    yield put(getUserDetails())
  } catch(e) {
    yield put({ type: 'UPDATE_USER_DETAILS_FAILED', message: e.message })
  }
}

function* watchFetchUserDetails() {
  yield takeLatest('GET_USER_DETAILS_REQUESTED', fetchUserDetails)
}

function* watchUpdateUserDetails() {
  yield takeLatest('UPDATE_USER_DETAILS_REQUESTED', updateUserDetails)
}

export default function* userSaga() {
  yield all([
    watchFetchUserDetails(),
    watchUpdateUserDetails()
  ])
}

