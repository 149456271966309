import { useField } from 'formik'
import Select from 'react-select'
import { DashBoardModalBodyWrapper, Error } from './sharedStyles'

const FormikSelect = ({
  label,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  const { options } = props
  const { touched, error } = meta
  const { setValue } = helpers

  return (
    <DashBoardModalBodyWrapper>
      <label htmlFor={props.id || props.name}>{label}<span>*</span></label>
      <Select
        styles={props.styles}
        defaultValue={props.defaultValue}
        options={options}
        name={field.name}
        onChange={(option) => setValue(option.value)}
        instanceId={props.iid}
        components={props.components}
      />

      {touched && error ? (
        <Error className="error">{error}</Error>
      ) : null}
    </DashBoardModalBodyWrapper>
  )
}

export default FormikSelect
