import React from 'react'
import { customSelect, Loading } from '../components/sharedStyles'
import Select from 'react-select'

import { ReactComponent as ArrowUp } from '../assets/icons/arrow_drop_up.svg'

const CustomSelect = ({ options, loading }) => {

  if (loading) return <Loading />

  let defaultOption = []
  if (options.hasOwnProperty('object_list')) {
    defaultOption = [{ id: options.object_list[0]?.id, title: options.object_list[0]?.title }]
  }

  return (
    <Select
      styles={customSelect}
      maxWidth='252px'
      options={options?.object_list}
      getOptionLabel={e => e.title}
      getOptionValue={e => e.id}
      defaultValue={defaultOption}
      onChange={option => console.log(option.id, option.user_id)}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => <ArrowUp />
      }}
    />
  )
}

export default CustomSelect
