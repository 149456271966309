import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Tab } from 'bootstrap'
import { Tabs } from 'react-bootstrap'

const TabsWrapper = styled.div`
  ul {
    margin-bottom: -2px !important;
  }

  .nav-link {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.44px;
    color: #747379;
    font-weight: 500;
    padding: .7rem 3rem;
  }

  button.active {
    background-color: #F0F2F3 !important;
    border-radius: 4px 4px 0px 0px;
    color: #002375 !important;
  }
`

const CustomTabs = (props) => {
  const { tabs, id, defaultKey, getKey } = props

  const [key, setKey] = useState(defaultKey)
  const sendKey = (k) => {
    setKey(k)
    getKey(k)
  }
  return (
    <TabsWrapper>
      <Tabs
        id={id}
        activeKey={key}
        onSelect={k => sendKey(k)}
        className="mb-3"
        transition={false}
      >
        {tabs.map(tab => (
          <Tab
            key={tab.eventKey}
            eventKey={tab.eventKey}
            title={tab.title}
          >
            {tab.eventKey === key && tab.Component}
          </Tab>
        ))}
      </Tabs>
    </TabsWrapper>

  )
}

export default CustomTabs

CustomTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  defaultKey: PropTypes.string.isRequired,
  getKey: PropTypes.func,
}

CustomTabs.defaultProps = {
  getKey: () => {}
}

