import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { breakPoints, mainColors } from '../constants/constants'
import { Carousel } from 'react-bootstrap'

import personImage from '../assets/home/carousel-test-image.png'
import quote from '../assets/home/quote.svg'

import { ReactComponent as Next } from '../assets/home/next-arrow.svg'
import { ReactComponent as Prev } from '../assets/home/prev-arrow.svg'

const translationUrl = 'home.testimonials.carousel.item-'

const data = [
  {
    name: "name",
    position: "position",
    quote: "quote",
    image: personImage
  },
  {
    name: "name",
    position: "position",
    quote: "quote",
    image: personImage
  },
  {
    name: "name",
    position: "position",
    quote: "quote",
    image: personImage
  },
]

const CustomCarousel = styled(Carousel)`
  min-height: 630px;
  width: 100%;
  height: 100%;
  position: relative;

  .carousel-inner {
    display: flex;
    height: 100%;
  }

  .carousel-caption {
    right: 0;
    left: 0;
  }

  .carousel-control-prev,
  .carousel-control-next {
    svg {
      position: absolute;
      bottom: 195px;
    }
  }

  .carousel-control-prev {
    svg {
      right: 0;
    }
  }

  .carousel-control-next {
    svg {
      left: 0;
    }
  }

  .carousel-caption{
    bottom: 3.25rem;
  }
`

const Quote = styled.div`
  max-width: 710px;
  width: 100%;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.03em;
  color: #3F3D56;
  margin: 0 auto;
  text-align: center;
  position: relative;

  p {
    position: absolute;
    top: 175px;

    @media (max-width: ${breakPoints.sm}) {
      top: 120px;
    }

    &:before {
      content: url(${quote});
      top: -55px;
      position: absolute;
      left: 0;
    }
  }
`

const Item = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #201F2E;
  }

  p {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: ${mainColors.transparentGray};
  }
`

const Image = styled.div`
  border-radius: 50px;
  /*border: 1px solid ${mainColors.blue};*/
  overflow: hidden;
  padding-bottom: 15px;
  position: relative;

  img {
    object-fit: fill;
    width: 80px;
    height: 80px;
  }
`

const StyledCarousel = () => {
  const { t } = useTranslation()

  const items = () => (
    data.map((person, index) => (
      <Carousel.Item key={index}>
          <Quote>
            <p>{t(translationUrl + index + '.' + person.quote)}</p>
          </Quote>
        <Carousel.Caption>
          <Item>
            <Image>
              <img src={person.image} alt={t(translationUrl + index + '.' + person.name)} />
            </Image>
            <div>
              <h3>{t(translationUrl + index + '.' + person.name)}</h3>
              <p>{t(translationUrl + index + '.' + person.position)}</p>
            </div>
          </Item>
        </Carousel.Caption>
      </Carousel.Item>
    ))
  )

  return (
    <CustomCarousel
      fade
      nextLabel=""
      prevLabel=""
      prevIcon={<Prev />}
      nextIcon={<Next />}
    >
      {items()}
    </CustomCarousel>
  )
}

export default StyledCarousel
