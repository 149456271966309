import React from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { breakPoints, mainColors } from '../../../constants/constants'
import Page from '../../views/Page'
import { Col } from 'react-bootstrap'

import treeStructure from '../../../assets/home/tree-structure.svg'
import fileSearch from '../../../assets/home/file-search.svg'
import projectorScreenChart from '../../../assets/home/projector-screen-chart.svg'
//import analytics from '../../../assets/home/analytics-icon.svg'

const data = [
  {
    icon: treeStructure,
    title: "badge_1.title",
    subtitle: "badge_1.subtitle"
  },
  {
    icon: fileSearch,
    title: "badge_2.title",
    subtitle: "badge_2.subtitle"
  },
  {
    icon: projectorScreenChart,
    title: "badge_3.title",
    subtitle: "badge_3.subtitle"
  },
]

const StyledSection = styled.section`
  background: ${mainColors.white};
  padding-top: 290px;
  padding-bottom: 290px;

  @media (max-width: ${breakPoints.sm}) {
    padding-top: 12%;
    padding-bottom: 12%;
  }
`

const TitleCol = styled(Col)`
  text-align: center;
  padding-bottom: 80px;

  h2 {
    font-weight: bold;
    font-size: 44px;
    line-height: 56px;
    color: ${mainColors.lighterGray};
  }
`

const WrapperCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  padding-left: 125px;
  padding-right: 50px;
  position: relative;

  @media (min-width: ${breakPoints.sm}) {
    &:before {
      position: absolute;
      top: 46px;
      display: block;
      content: '';
      margin: 5px 0;
      height: 20px;
      background: repeating-linear-gradient(to right,#59A1FA 0,#59A1FA 6px,transparent 3px,transparent 10px) top;
      opacity: .5;
      background-size: 100% 2px;
      background-repeat: no-repeat;
      width: 70%;
      left: 17%;
    }
  }

  @media (max-width: ${breakPoints.sm}) {
    flex-direction: column;
    padding-left: 12px;
    padding-right: 12px;
  }
`

const Badge = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  z-index: 1;

  span {
    display: block;
    text-align: center;
    max-width: 200px;
    width: 100%;

    &:first-child {
      font-weight: 500;
      font-size: 24px;
      color: ${mainColors.lighterGray};
      padding-top: 26px;
      padding-bottom: 16px;
      line-height: 1;
    }
    &:last-child {
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.3px;
      color: ${mainColors.transparentGray};
    }
  }
`

const Icon = styled.div`
  width: 95px;
  height: 95px;
  background: ${mainColors.darkBlue};
  border-radius: 50px;
  background-image: url(${({ $url }) => $url});
  background-repeat: no-repeat;
  background-position: center;
`

const HowItWorks = () => {
  const { t } = useTranslation()

  const badges = (data) => (
    data.map((item, index) => (
      <Badge key={index}>
        <Icon $url={item.icon}/>
        <div>
          <span>{t('home.howItWorks.badges.'+item.title)}</span>
          <span>{t('home.howItWorks.badges.'+item.subtitle)}</span>
        </div>
      </Badge>
    ))
  )

  return (
    <StyledSection>
      <Page>
        <TitleCol md={12}>
          <h2>{t('home.howItWorks.title')}</h2>
          <p>
            <Trans>{t('home.howItWorks.subtitle')}</Trans>
          </p>
        </TitleCol>
        <WrapperCol md={12}>
          {badges(data)}
        </WrapperCol>
      </Page>
    </StyledSection>
  )
}

export default HowItWorks
