import * as type from '../types'

export const deleteTableRow = (rowId, table_key, project_id) => (
  {
    type: type.DELETE_TABLE_DATA_ROW_REQUESTED,
    rowId,
    table_key,
    project_id
  }
)

export const addTableHeader = (header, key) => (
  {
    type: type.ADD_TABLE_HEADER,
    header,
    key
  }
)

export const removeTableHeader = (header, key) => (
  {
    type: type.REMOVE_TABLE_HEADER,
    header,
    key
  }
)

export const toggleTableColumns = (key) => (
  {
    type: type.TOGGLE_TABLE_COLUMNS,
    key
  }
)

export const toggleActiveRow = (id, key) => (
  {
    type: type.TOGGLE_ACTIVE_ROW,
    id,
    key
  }
)

export const fetchTableData = (key, projectId) => (
  {
    type: type.GET_TABLE_DATA_REQUESTED,
    key,
    projectId
  }
)

export const updateTableData = (key, values, id) => (
  {
    type: type.UPDATE_TABLE_DATA_REQUESTED,
    key,
    values,
    id
  }
)
