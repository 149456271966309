import React, { useState, useEffect, useRef, forwardRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { actionIcons, currencySymbols, mainColors, Role, tableKeys } from '../../constants/constants'
import ProjectLayout from './ProjectLayout'
import CustomLinkButton from '../../components/CustomLinkButton'
import CustomTable, { defaultColumns } from '../../components/CustomTable'
import { CustomCellWrapper, HeaderWrapper, Loading, StyledLoading } from '../../components/sharedStyles'
import { fetchTableData, toggleActiveRow } from '../../redux/actions/table'
import CustomTableFilters from '../../components/CustomTableFilters'
import { getProjectDetails } from '../../redux/actions/projects'
import DropZone from '../../components/DropZone'
import { checkAccess } from '../../components/helpers'
import CustomTabs from '../../components/CustomTabs'
import { usePrevious } from '../../components/hooks/hooks'

const CustomCell = styled.span`
  padding: 1px 8px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 10px;

  ${({ $color }) => $color === 'UNDER_REVIEW' && css`
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2081FA;
    border: 1px solid #2081FA !important;
    color: #2081FA;
  `}
`

const GenericTabComponent = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    table_key,
    project_id,
    cols,
    outsideFilter,
    hideHeaders,
    showPagination,
    activeRowId,
    toggleActiveRow,
    showStandardColumns,
    show_action_items,
    show_doc_interaction,
    currency,
    size
  } = props

  useEffect(() => {
    dispatch(fetchTableData(table_key, project_id))
  }, [dispatch, table_key, project_id])

  const loading = useSelector(state => state.tables[table_key].loading)
  const tableData = useSelector(state => state.tables[table_key].data)

  return (
    <>
      {loading ? (
        <StyledLoading />
      ) : (
        <CustomTable
          table_key={table_key}
          project_id={project_id}
          table_data={tableData}
          cols={cols}
          outsideFilter={outsideFilter}
          hideHeaders={hideHeaders}
          showPagination={showPagination}
          activeRowId={activeRowId}
          toggleActiveRow={toggleActiveRow}
          showStandardColumns={showStandardColumns}
          show_action_items={show_action_items}
          show_doc_interaction={show_doc_interaction}
          ref={ref}
          currency={currency}
          size={size}
        />
      )}
    </>
  )
})

const Budget = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const addBudgetRef = useRef()
  const projectId = location.state?.id

  const details = useSelector(state => state.projects.project_details)
  const loading = useSelector(state => state.projects.loading)

  const loadingDocuments = useSelector(state => state.tables.budget_documents_table.loading)
  const documentsTableData = useSelector(state => state.tables.budget_documents_table.data)

  const loadingBudgetLines = useSelector(state => state.tables.budget_lines_table.loading)
  const budgetLinesTableData = useSelector(state => state.tables.budget_lines_table.data)
  const activeRowIdBudgetLines = useSelector(state => state.tables.budget_lines_table.active_row)

  const toggleTableHeaders = useSelector(state => state.tables.budget_table)

  const [redirectAfterUpload, setRedirectAfterUpload] = useState(false)

  useEffect(() => {
    if (checkAccess([Role.admin, Role.dev])) {
      dispatch(fetchTableData(tableKeys.budget_lines_table, projectId))
      dispatch(fetchTableData(tableKeys.budget_documents_table, projectId))
    }
  }, [dispatch, projectId])

  const COLUMNS_DOCUMENTS_PENDING = [
    {
      Header: 'Document',
      Footer: 'Document',
      accessor: 'document_name',
      disableFilters: true,
      doNotHide: true,

      Cell: (tableProps) => <CustomCellWrapper>{tableProps.value}</CustomCellWrapper>
    },
    {
      Header: 'Upload Date',
      Footer: 'Upload Date',
      accessor: 'uploaded_at',

      Cell: (tableProps) => {
        const event = new Date(tableProps.value)
        const options = { year: 'numeric', month: 'long', day: 'numeric' }
        const date = event.toLocaleDateString('en-GB', options)
        return (
          <CustomCellWrapper>
            <CustomCell>
              {date}
            </CustomCell>
          </CustomCellWrapper>
        )
      }
    },
    {
      Header: 'Status',
      Footer: 'Status',
      accessor: 'status',
      doNotHide: true,

      Cell: (tableProps) => (
        <CustomCellWrapper>
          <CustomCell $color={tableProps.value}>
            {t('document_status.' + (tableProps.value).toLowerCase())}
          </CustomCell>
        </CustomCellWrapper>
      ),
    },
  ]

  const COLUMNS_BUDGET_LINES = [
    {
      Header: 'Budget Line',
      accessor: 'id',
      disableFilters: true,
      doNotHide: true,
      customWidth: '100px',

      Cell: (tableProps) => {
        const index = Number(tableProps.row.id) + 1
        return <CustomCellWrapper>{index}</CustomCellWrapper>
      }
    },
    {
      Header: 'Budget item description',
      accessor: 'label',
    },
    {
      Header: 'Cost Type',
      accessor: 'type',
      componentType: 'isSelect',
      selectOptions: [
        { id: 'soft', title: t('costs.soft_cost') },
        { id: 'land', title: t('costs.land_cost') },
        { id: 'hard', title: t('costs.hard_cost') },
      ],
    },
    {
      Header: 'Value without VAT',
      accessor: 'amount',
      componentType: 'inputNumber'
    },
    {
      Header: 'Executed budget',
      accessor: 'executed_amount',
      componentType: 'inputNumber'
    },
    {
      Header: 'Remaining budget',
      accessor: 'remaining_amount',
      componentType: 'inputNumber'
    },
  ]

  useEffect(() => {
    dispatch(getProjectDetails(projectId))
  }, [dispatch, projectId])

  const [outsideFilter, setOutsideFilter] = useState('')
  const ALL_COLUMNS = COLUMNS_BUDGET_LINES.concat(defaultColumns())

  let tabs = []
  if (checkAccess([Role.monitor])) {
    const removeStatusColumn = COLUMNS_DOCUMENTS_PENDING.pop()

    tabs = [
      {
        eventKey: 'waiting_review',
        title: t('tabs.waiting_review'),
        Component: (
          <GenericTabComponent
            table_key={tableKeys.budget_documents_table}
            project_id={projectId}
            cols={COLUMNS_DOCUMENTS_PENDING}
            showStandardColumns
            show_action_items={[actionIcons.download, actionIcons.reject]}
            showPagination={false}
            toggleActiveRow={toggleActiveRow}
            size='2fr 2.5fr 1fr'
          />
        )
      },
      {
        eventKey: 'approved',
        title: t('tabs.approved'),
        Component: (
          <GenericTabComponent
            ref={addBudgetRef}
            table_key={tableKeys.budget_lines_table}
            project_id={projectId}
            cols={COLUMNS_BUDGET_LINES}
            outsideFilter={outsideFilter}
            hideHeaders={toggleTableHeaders.hide_headers}
            showPagination={true}
            activeRowId={activeRowIdBudgetLines}
            toggleActiveRow={toggleActiveRow}
            showStandardColumns={true}
            show_action_items={[actionIcons.edit, actionIcons.delete]}
            currency={currencySymbols[details?.currency]}
          />
        )
      },
      {
        eventKey: 'rejected',
        title: t('tabs.rejected'),
        Component: (
          <GenericTabComponent
            table_key={tableKeys.budget_documents_table}
            project_id={projectId}
            cols={COLUMNS_DOCUMENTS_PENDING}
            showStandardColumns
            show_action_items={[actionIcons.ocr]}
            showPagination={false}
            toggleActiveRow={toggleActiveRow}
            size='2fr 2.5fr 1fr'
          />
        )
      }
    ]
  }

  const defaultTab = 'waiting_review'
  const [tabKey, setTabKey] = useState(defaultTab)
  const activeTabkey = key => setTabKey(key)

  const prevActiveRowId = usePrevious(activeRowIdBudgetLines)
  const prevBudgetDataLines = usePrevious(budgetLinesTableData?.length)
  const [ disable, setDisable] = useState(false)

  useEffect(() => {
    if(prevActiveRowId && prevActiveRowId === budgetLinesTableData.length) {
      setDisable(true)
    }

    if(prevBudgetDataLines !==  budgetLinesTableData?.length) {
      //enable button when a new row is added
      setDisable(false)
    }

    if(checkAccess([Role.monitor]) && tabKey !== 'approved') {
      setDisable(false)
    }
  }, [budgetLinesTableData, prevActiveRowId, tabKey, prevBudgetDataLines])

  const newData = []
  budgetLinesTableData?.map(obj => {
    let { amount = 0, executed_amount = 0 } = obj
    const newObj = {...obj}
    newObj.executed_amount = executed_amount
    newObj.remaining_amount = amount - executed_amount

    return newData.push(newObj)
  })

  return (
    <ProjectLayout
      header={(
        <HeaderWrapper>
          <h2>{t('project.routes.budget')}</h2>
        </HeaderWrapper>
      )}
      footer={
        <>
          {((checkAccess([Role.admin, Role.dev]) && budgetLinesTableData?.length > 0) || tabKey === 'approved') && (
            <CustomLinkButton
              type="button"
              onClick={() => addBudgetRef.current.onAddLine()}
              textColor={mainColors.white}
              innerColor={mainColors.neonBlue}
              borderColor={mainColors.neonBlue}
              text={t('buttons.add_budget_line')}
              button
              disabled={disable || activeRowIdBudgetLines !== null ? true : false}
            />
          )}
        </>
      }
      footerAlignment="flex-end"
    >
      {checkAccess([Role.admin, Role.dev]) && (
        (typeof budgetLinesTableData === 'undefined' || budgetLinesTableData.length < 1) && (typeof documentsTableData === 'undefined' || documentsTableData.length < 1) && !redirectAfterUpload ? (
          <DropZone
            setRedirectAfterUpload={setRedirectAfterUpload}
            items='budgets'
            subtext='Upload your Excel budget file right now'
          />
        ) : (
          <div>
            {loadingDocuments || loadingBudgetLines ? (
              <Loading />
            ) : (
              typeof budgetLinesTableData === 'undefined' || budgetLinesTableData?.length < 1 ? (
                <CustomTable
                  table_key={tableKeys.budget_documents_table}
                  table_data={documentsTableData}
                  cols={COLUMNS_DOCUMENTS_PENDING}
                  showStandardColumns={false}
                  show_action_items={[]}
                  showPagination={false}
                  toggleActiveRow={toggleActiveRow}
                  size='2fr 2.5fr 1fr'
                />
              ) : (
                <CustomTable
                  table_key={tableKeys.budget_lines_table}
                  project_id={projectId}
                  table_data={newData}
                  cols={COLUMNS_BUDGET_LINES}
                  outsideFilter={outsideFilter}
                  hideHeaders={toggleTableHeaders.hide_headers}
                  showPagination
                  activeRowId={activeRowIdBudgetLines}
                  toggleActiveRow={toggleActiveRow}
                  showStandardColumns
                  show_action_items={[actionIcons.edit, actionIcons.delete]}
                  ref={addBudgetRef}
                  currency={currencySymbols[details?.currency]}
                  size='1fr 2.5fr 2.5fr 2fr 2fr 2fr 1.5fr'
                />
              )
            )}
          </div>
        )
      )}

      {checkAccess([Role.monitor]) && (
        <CustomTabs
          id="custom-data-tabs"
          tabs={tabs}
          defaultKey={defaultTab}
          getKey={(k) => activeTabkey(k)}
        />
      )}

    </ProjectLayout>
  )
}

export default Budget
