import * as type from '../types'

const initialState = {
  allProjects: [],
  loading: false,
  create_project_loading: false,
  project_details: [],
  error: null
}

export default function projects(state = initialState, action) {
  switch (action.type) {
    case type.GET_PROJECTS_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case type.GET_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allProjects: action.projects,
      }
    case type.GET_PROJECTS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.message,
      }
    case type.CREATE_PROJECT_REQUESTED:
      return {
        ...state,
        create_project_loading: true,
      }
    case type.CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        create_project_loading: false,
      }
    case type.CREATE_PROJECT_FAILED:
      return {
        ...state,
        create_project_loading: false,
        error: action.message,
      }
    case type.GET_PROJECT_DETAILS_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case type.GET_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        project_details: action.project_details,
      }
    case type.GET_PROJECT_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.message,
      }
    default:
      return state
  }
}
