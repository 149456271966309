import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { Col } from 'react-bootstrap'
import { breakPoints, mainColors, urls, social } from '../../constants/constants'
import Page from '../views/Page'
import Logo from '../../components/Logo'
import Subscribe from '../../components/Subscribe'

import { ReactComponent as Facebook } from '../../assets/LogoFacebook.svg'
import { ReactComponent as Twitter } from '../../assets/LogoTwitter.svg'

const mainLinks = [
  {
    to: urls.about_us,
    title: 'footerMenu.about_us'
  },
]

const footerLinks = [
  {
    to: urls.terms,
    title: 'footerMenu.terms'
  },
  {
    to: urls.privacy,
    title: 'footerMenu.policy'
  },
  {
    to: urls.cookies,
    title: 'footerMenu.cookies'
  }
]

const socialItems = [
  {
    href: social.facebook,
    icon: <Facebook />
  },
  {
    href: social.twitter,
    icon: <Twitter />
  }
]


const StyledWrapper = styled.footer`
  background: ${mainColors.footerBlue};
  min-height: 143px;
  height: 100%;
  padding-top: 40px;
  padding-bottom: 34px;
  color: ${mainColors.white};

  @media (max-width: ${breakPoints.sm}) {
    .row {
      flex-direction: column;
    }
  }
`

const LeftCol = styled(Col)`
  display: flex;
  flex: 1;
  align-items: flex-start;
`

const SecondaryMenu = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 20px;
`

const FooterMenu = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const SubscribeWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-left: 15%;

  @media (max-width: ${breakPoints.sm}) {
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0;
    padding-bottom: 10px;
  }
`

const MiddleCol = styled(Col)`
  justify-content: space-between;
  display: flex;
  flex: 2;

  @media (max-width: ${breakPoints.sm}) {
    flex-direction: column;
  }
`

const RightCol = styled(Col)`
  justify-content: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: ${breakPoints.sm}) {
    justify-content: flex-start;
    align-items: flex-start;
  }
`

const StyledNavLink = styled(NavLink)`
  color: ${mainColors.white};
  text-decoration: none;
  font-size: 14px;
  line-height: 46px;
  white-space: nowrap;
`

const Title = styled.h3`
  font-weight: 700;
  font-size: 18px;
  line-height: 46px;
  padding-top: 10px;
`

const Icons = styled.div`
  display: flex;

  a:first-child {
    margin-right: 15px;
    display: block;
  }
`

const Footer = () => {
  const { t } = useTranslation()

  const links = (data) => data.map((link, index) => (
    <StyledNavLink key={index} to={link.to}>{t(link.title)}</StyledNavLink>
  ))

  const items = (data) => data.map((item, index) => (
    <a key={index} href={item.href}>
      {item.icon}
    </a>
  ))

  return (
    <StyledWrapper>
      <Page>
        <LeftCol>
          <Logo
            color="white"
            textColor="white"
            separatorColor="white"
          />
        </LeftCol>
        <MiddleCol>
          <SecondaryMenu>
            {links(mainLinks)}
          </SecondaryMenu>
          <FooterMenu>
            {links(footerLinks)}
          </FooterMenu>
          <SubscribeWrapper>
            <Title>{t('subscribe.title')}</Title>
            <Subscribe />
          </SubscribeWrapper>
        </MiddleCol>
        <RightCol>
          <Title>{t('footer.follow')}</Title>
          <Icons>
            {items(socialItems)}
          </Icons>
        </RightCol>
      </Page>
    </StyledWrapper>
  )
}

export default Footer
