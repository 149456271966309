import React from 'react'
import styled from 'styled-components'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { StyledInput } from './sharedStyles'

import { ReactComponent as ArrowRight} from '../assets/ArrowRight.svg'

const Form = styled.form`
  display: flex;
`

const Submit = styled.button`
  width: 45px;
  height: 45px;
  margin-left: -6px;
  background: #3F8CFF;
  border: 1px solid #3F8CFF;
  border-radius: 0px 8px 8px 0px;
`

function Subscribe() {
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      email: '',
    },
  
    onSubmit: values => {
      console.log(JSON.stringify(values, null, 2))
    },
  })

  return (
    <Form onSubmit={formik.handleSubmit}>
    <StyledInput
      id="email"
      name="email"
      type="email"
      onChange={formik.handleChange}
      value={formik.values.email}
      placeholder={t('subscribe.email_address')}
    />
    <Submit type="submit">
      <ArrowRight />
    </Submit>
  </Form>
  )
}

export default Subscribe
