import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import './i18n/config'
import 'bootstrap/dist/css/bootstrap.min.css'

import AppRoutes from './Routes'
import IdleTimerContainer from './components/IdleTimerContainer'
import CustomModal from './components/CustomModal'
import AuthModal from './components/AuthModal'

const App = () => {

  return (
    <BrowserRouter>
      <IdleTimerContainer />
      <div />
      <CustomModal />
      <AuthModal />
      <AppRoutes />
    </BrowserRouter>
  )
}

export default App
