import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { menuColors } from '../constants/constants'
import { useTranslation } from 'react-i18next'

//import { ReactComponent as BlueLogo } from '../assets/Logo.svg'
import { ReactComponent as WhiteLogo } from '../assets/LogoWhite.svg'
import { ReactComponent as MinLogo } from '../assets/LogoMin.svg'
import { ReactComponent as BlueLogo } from '../assets/NewBlueLogo.svg'

const LogoSvg = {
  blue: <BlueLogo />,
  white: <WhiteLogo />,
  min: <MinLogo />,
}

const LogoTextColor = {
  gray: menuColors.logoTextGray,
  white: menuColors.logoTextWhite,
}

const barTextColor = {
  gray: menuColors.logoBarGray,
  white: menuColors.logoBarWhite,
}

const LogoWrapper = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  svg {
    margin-top: -6px;
    height: 54px;
    width: auto;
  }
`

const LogoText = styled.div`
  font-weight: 300;
  font-size: 13px;
  margin-left: 14px;
  padding-left: 14px;
  color: ${({ $color }) => $color };
  position: relative;

  &:after {
    content: '';
    background: ${({ $barColor }) => $barColor };
    position: absolute;
    bottom: 0;
    left: 0;
    top: 6px;
    height: 28px;
    width: 1px;
  }
`

const Logo = (props) => {
  const { color, textColor, separatorColor, className, to, showText, onClick } = props
  const { t } = useTranslation()

  return (
    <LogoWrapper to={to} className={className} onClick={onClick}>
      {LogoSvg[color]}
    </LogoWrapper>
  )
}

export default Logo

Logo.propTypes = {
  color: PropTypes.string,
  textColor: PropTypes.string,
  separatorColor: PropTypes.string,
  to: PropTypes.string,
  type: PropTypes.bool,
  onClick: PropTypes.func,
}

Logo.defaultProps = {
  color: '#FFF',
  textColor: '#FFF',
  separatorColor: '#FFF',
  to: '/',
  showText: true,
  onClick: () => {}
}

