import React from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { breakPoints, mainColors } from '../../../constants/constants'
import Page from '../../views/Page'
import { Col } from 'react-bootstrap'

import hero from '../../../assets/about-us/hero.png'

const StyledSection = styled.section`
  background: url(${hero});
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: hue, normal;
  padding-top: 180px;
  padding-bottom: 180px;

  @media (max-width: ${breakPoints.sm}) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`

const StyledPage = styled(Page)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const StyledDiv = styled.div`
  margin: 15% auto;
  max-width: 564px;
  width: 100%;

  h1 {
    font-size: 5em;
    line-height: 92px;
    color: ${mainColors.darkGray};
    font-weight: 700;
    text-transform: uppercase;

    span {
      color: ${mainColors.blue};
    }
  }

  P {
    padding-top: 27px;
  }

  a {
    display: inline-block;
    margin-left: 0 !important;
    margi-top: 25px;
  }

  @media (max-width: ${breakPoints.sm}) {
    max-width: 100%;
    margin: 20px auto;
    padding-bottom: 10px;
    h1 {
      font-size: 3em;
      line-height: 1;
    }
  }
`

const Hero = () => {
  const { t } = useTranslation()

  return (
    <StyledSection>
      <StyledPage>
        <Col>
          <StyledDiv>
            <h1>{t('about_us.hero.title')}</h1>
            <p>
              <Trans>{t('about_us.hero.subtitle')}</Trans>
            </p>
          </StyledDiv>
        </Col>
      </StyledPage>
    </StyledSection>
  )
}

export default Hero
