import { useEffect, useState, useRef } from 'react'

export const useViewport = () => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize)
    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])

  return { width }
}

export const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useOnScreen = (ref, threshold = 1) => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const currentRef = ref.current.children[0].children[0]
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting)
      },
      {
        threshold,
      }
    )
    if (ref.current) {
      observer.observe(currentRef)
    }
    return () => {
      observer.unobserve(currentRef)
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting
}
