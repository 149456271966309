import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { mainColors } from '../constants/constants'

import check from '../assets/icons/check.svg'

const StyledInput = styled.input`
  display: none;

  + label {
    :before {
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      margin-right: 10px;
      background: transparent;
      border: 2px solid #C2C9D1;
      border-radius: 2px;
    }
  }

  :checked + label:before {
    content: '';
    background: url("${check}") no-repeat center;
    background-size: contain;
    background-color: ${mainColors.neonBlue};
    border: 2px solid ${mainColors.neonBlue};
    background-size: inherit;
    padding-right: 10px;
    border-radius: 2px;
  }
`

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-bottom: 0;
  background-size: contain;
  cursor: pointer;

  span {
    opacity: .5;
  }
`

export const Checkbox = React.forwardRef(({ indeterminate, className, label, id, ...rest }, ref) => {
  const defaultRef = React.useRef()
  const resolvedRef = ref || defaultRef

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <div className={className}>
      <StyledInput
        type='checkbox'
        id={id}
        ref={resolvedRef}
        {...rest}
      />
      <StyledLabel
        htmlFor={id}
      >
        {label && (
          <span>{label}</span>
        )}
      </StyledLabel>
    </div>
  )
})

Checkbox.propTypes = {
  label: PropTypes.string,
}

Checkbox.defaultProps = {
  label: ''
}
