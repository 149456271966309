import React from 'react'
import { useTranslation } from 'react-i18next'

import MainLayout from '../partials/MainLayout'
import Hero from './sections/Hero'
import UnderHero from './sections/UnderHero'
import Pricing from './sections/Pricing'
import ContactUs from './sections/ContactUs'
import OurProduct from  './sections/OurProduct'
import BenefitsForLenders from  './sections/BenefitsForLenders'
import HowItWorks from './sections/HowItWorks'
import Testimonials from './sections/Testimonials'
import PageMetaTitle from '../../components/PageMetaTitle'

const Home = () => {
  const { t } = useTranslation()

  return (
    <MainLayout>
      <PageMetaTitle title={t('mainMenu.home')} />
      <Hero />
      <UnderHero />
      <OurProduct />
      <BenefitsForLenders />
      <HowItWorks />
      {false && (
        <>
          <Testimonials />
          <Pricing />
        </>
      )}
      <ContactUs />
    </MainLayout>
  )
}

export default Home
