import React from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { breakPoints, mainColors } from '../../../constants/constants'
import Page from '../../views/Page'
import { Col } from 'react-bootstrap'

import piramid from '../../../assets/home/piramid.svg'

const badgeData = [
  "text.budget_configuration_setup",
  "text.invoice_agreements_tracking",
  "text.documents_data_extraction",
  "text.draw_management",
  "text.pre_spa_tracking",
]

const StyledSection = styled.section`
  background: ${mainColors.white};
  padding-top: 100px;
`

const TitleCol = styled(Col)`
  text-align: center;
  padding-bottom: 40px;

  h2 {
    font-weight: bold;
    font-size: 44px;
    line-height: 56px;
    color: ${mainColors.lighterGray};
  }
`

const WrapperCol = styled(Col)`
  display: flex;
  background-image: url(${piramid});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 30%;
  min-height: 630px;
  margin: 0 auto;

  @media (max-width: ${breakPoints.sm}) {
    flex-direction: column;
  }
`

const BadgesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 200px;

  div {
    border-right: 4px solid ${mainColors.darkBlue};
    margin-right: 15px;
  }

  @media (max-width: ${breakPoints.sm}) {
    order: 2;
    width: 100%;
    hight: inherit;
    div {
      margin-right: 0;
    }
  }
`

const Badge = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 15px;
  background: ${mainColors.white};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0px 8px 8px 0px;
  margin: 24px 0px;
  max-width: 269px;
  width: 100%;
  border-radius: 4px;
  color: ${mainColors.darkGray};
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  height: 78px;
  line-height: 26px;
  @media (max-width: ${breakPoints.sm}) {
    max-width: 100%;
  }
`

const OurProduct = () => {
  const { t } = useTranslation()

  const badges = (data) => (
    data.map((item, index) => (
      <Badge key={index}>{t('home.ourProduct.badges.'+item)}</Badge>
    ))
  )

  return (
    <StyledSection id="our-product">
      <Page>
        <TitleCol md={12}>
          <h2>{t('home.ourProduct.title')}</h2>
          <p>
            <Trans>{t('home.ourProduct.subtitle')}</Trans>
          </p>
        </TitleCol>
        <WrapperCol md={8}>
          <BadgesWrapper>
            {badges(badgeData)}
          </BadgesWrapper>
        </WrapperCol>
      </Page>
    </StyledSection>
  )
}

export default OurProduct
