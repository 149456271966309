

import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import IdleTimer from 'react-idle-timer'
import { openModal, closeModal } from '../redux/actions/modal-auth'
import { closeModal as closeCustomModal } from '../redux/actions/modal-custom'
import { useSelector, useDispatch } from 'react-redux'
import { logout_to } from '../redux/actions/auth'
import { timer, urls } from '../constants/constants'

const IdleTimerContainer = () => {
  const idleTimerRef = useRef(null)
  const sessionTimerRef = useRef(null)
  let navigate = useNavigate();
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth.isAuth)
  const { automatic_logout_after, show_idle } = timer

  const logout = () => {
    dispatch(logout_to())
    dispatch(closeModal())
    dispatch(closeCustomModal())
    clearTimeout(sessionTimerRef.current)
    localStorage.removeItem('state')
    navigate(urls.sign_in, { replace: true })
  }

  const onIdle = () => {
    const modalData = {
      button_save: 'Stay logged',
      button_close: 'Logout',
      info: 'You are idle and will be logged out soon!',
      isIdle: true,
      ref: sessionTimerRef,
    }

    dispatch(openModal(modalData))
    sessionTimerRef.current = setInterval(logout, automatic_logout_after)
  }

  return (
    <IdleTimer
      ref={idleTimerRef}
      timeout={show_idle}
      onIdle={auth ? onIdle : () => {}}
    />
  )
}

export default IdleTimerContainer
