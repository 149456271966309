import * as type from '../types'

const initialState = {
  isAuth: null,
  userData: null,
  error: null,
  loading: false,
}

export default function users(state = initialState, action) {
  switch(action.type) {
    case type.AUTH_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case type.AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuth: action.auth,
        userData: action.userData,
      }
    case type.AUTH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.message,
      }
    case type.AUTH_MANUAL_LOGOUT:
      return {
        ...state,
        isAuth: null,
        userData: null,
        error: null,
      }
    default:
      return state
  }
}
