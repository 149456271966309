import { all } from 'redux-saga/effects'
import authSaga from './authSaga'
import userSaga from './userSaga'
import projectsSaga from './projectsSaga'
import tablesSaga from './tablesSaga'

export default function* rootSaga() {
  yield all([
    authSaga(),
    userSaga(),
    projectsSaga(),
    tablesSaga(),
  ])
}
