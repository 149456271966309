import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PageMetaTitle from '../components/PageMetaTitle'
import DashLayout from './partials/DashLayout'
import { DashBoardModalBodyWrapper, LinksWrapper, Loading, sharedDashboardHeader, StyledInputDashboard } from '../components/sharedStyles'
import ProjectLayout from './partials/ProjectLayout'
import { mainColors } from '../constants/constants'
import { Checkbox } from '../components/Checkbox'
import CustomLinkButton from '../components/CustomLinkButton'
import { getUserDetails, updateUserDetails } from '../redux/actions/user'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FormikInput } from '../components/FormikInput'

const title = css`
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 32px !important;
  color: ${mainColors.dashboardDarkBlue} !important;
`

const Header = styled.div`
  ${sharedDashboardHeader}
  display: flex;
  align-items: center;
  justify-content: center;
`

const Titles = styled.ul`
  border: none !important;

  li {
    flex: 1;
    justify-content: flex-start;
    border-bottom: 1px solid #ECECEC;
    margin-right: 0 !important;
    padding-right: 35px;

    span {
      ${title}
    }
  }
`

const Content = styled.ul`
  border: none !important;
  justify-content: flex-start;
  padding-left: 0 !important;
  margin-top: -25px !important;

  li {
    flex: 1;
    justify-content: flex-start;


    &:first-child {
      padding-right: 35px;
    }

    input {
      max-width: 350px;
    }

    div {
      margin-bottom: 16px;
    }
  }
`

const Notifications = styled.div`
  ul {
    padding: 0;
    margin: 0;

    li span {
      ${title}
    }
  }
`

const Wrapper = styled.div`
  margin-top: 20px;
`

const StyledCustomLinkButton = styled(CustomLinkButton)`
  margin-left: 0 !important;
  margin-top: 22px;
  padding: 4px 20px;
`

const StyledLinksWrapper = styled(LinksWrapper)`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const StyledLoading = styled(Loading)`
  position: absolute;
  top: 50%;
  left: 50%;
`

const AccountSettings = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userDetails = useSelector(state => state.user.userDetails)
  const { email, user_name } = userDetails
  const loading = useSelector(state => state.user.loading)

  useEffect(() => {
    dispatch(getUserDetails())
  }, [dispatch])

  const HeaderInfo = (
    <Header>
      <h2>{t('account_settings.account_settings')}</h2>
    </Header>
  )

  const profile = [
    {
      label: t('account_settings.name'),
      name: "name",
      type: "text",
      placeholder: t('account_settings.name_placeholder'),
      disabled: true
    },
    {
      label: t('account_settings.surname'),
      name: "surname",
      type: "text",
      placeholder: t('account_settings.surname_placeholder')
    },
    {
      label: t('account_settings.email'),
      name: "email",
      type: "email",
      placeholder: t('account_settings.email_placeholder'),
      disabled: true
    }
  ]

  return (
    <DashLayout Component={HeaderInfo}>
      <PageMetaTitle title={t('account_settings.account_settings')} />
      <StyledLinksWrapper>
        <Titles>
          <li><span>{t('account_settings.profile')}</span></li>
          <li><span>{t('account_settings.change')}</span></li>
        </Titles>
        <ProjectLayout>
          {loading ? (
            <StyledLoading />
          ) : (
            <>
              <Formik
                initialValues={{
                  name: user_name,
                  surname: "",
                  email: email,
                  role_id: '0',
                  status: 'AWAITING_CONFIRMATION'
                }}
                validationSchema={Yup.object({
                  name: Yup.string()
                    .max(30, "Must be 30 characters or less")
                    .required(t('validations.required')),
                  email: Yup.string()
                    .max(50, "Must be 50 characters or less")
                    .required(t('validations.required'))
                })}
                onSubmit={async (values) => {
                  dispatch(updateUserDetails(values))
                }}
              >
                <Form>
                  <Content>
                    <li>
                      {profile.map(item => item.disabled? (
                        <FormikInput
                          key={item.name}
                          label={item.label}
                          name={item.name}
                          type={item.type}
                          placeholder={item.placeholder}
                          disabled
                        />
                      ) : (
                        <FormikInput
                          key={item.name}
                          label={item.label}
                          name={item.name}
                          type={item.type}
                          placeholder={item.placeholder}
                        />
                      ))}
                    </li>
                    <li>
                      <FormikInput
                        label={t('account_settings.current_pass')}
                        name="current_pass"
                        type="password"
                        placeholder="******"
                      />
                      <FormikInput
                        label={t('account_settings.new_pass')}
                        name="new_pass"
                        type="password"
                        placeholder="******"
                      />
                      <StyledCustomLinkButton
                        textColor={mainColors.white}
                        innerColor={mainColors.neonBlue}
                        borderColor={mainColors.neonBlue}
                        text={t('account_settings.update_password')}
                        type='submit'
                        button
                      />
                    </li>
                  </Content>
                </Form>
              </Formik>

              <Notifications>
                <ul>
                  <li>
                    <span>{t('account_settings.notifications')}</span>
                  </li>
                </ul>
                <Wrapper>
                  <Checkbox
                    type='checkbox'
                    id="receive-notifications"
                    defaultChecked={false}
                    onChange={(e) => {
                      const checked = e.target.checked
                      if (checked) {
                        console.log('yes')
                      } else {
                        console.log('no')
                      }
                    }}
                    label={t('account_settings.receive_notifications')}
                  />
                </Wrapper>
              </Notifications>
            </>
          )}
        </ProjectLayout>
      </StyledLinksWrapper>
    </DashLayout>
  )
}

export default AccountSettings

