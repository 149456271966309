import React from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { breakPoints } from '../../../constants/constants'
import Page from '../../views/Page'
import { Col } from 'react-bootstrap'
import ContactForm from '../../../components/ContactForm'

const StyledSection = styled.section`
  color: #515058;
  padding-top: 150px;
  padding-bottom: 150px;
`

const StyledPage = styled(Page)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`

const Wrapper = styled(Col)`
`

const StyledDiv = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  margin: 12% auto;

  p {
    font-size: 18px;
    line-height: 30px;
    padding-top: 16px;

    span {
      font-weight: 700;
    }
  }

  @media (max-width: ${breakPoints.sm}) {
    width: 100%;
  }

  input, textarea {
    background: #EDEDED;
  }
`

const ContactUs = () => {
  const { t } = useTranslation()

  return (
    <StyledSection>
      <StyledPage>
        <Wrapper>
          <StyledDiv>
            <h3>{t('about_us.contact.title')}</h3>
            <p>
              <Trans>{t('about_us.contact.subtitle')}</Trans>
            </p>
            <ContactForm />
          </StyledDiv>
        </Wrapper>
      </StyledPage>
    </StyledSection>
  )
}

export default ContactUs
