import { createStore, compose, applyMiddleware } from 'redux'
import rootReducer from './reducers/index.js'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas/index.js'

/* const saveToLocalStorage = (state) => {
  try {
    localStorage.setItem('state', '{"auth":' + JSON.stringify(state) + '}')
  } catch (e) {
    console.error(e)
  }
} */

const loadFromLocalStorage = () => {
  try {
    const stateStr = localStorage.getItem('state')
    return stateStr ? JSON.parse(stateStr) : undefined
  } catch (e) {
    console.error(e)
    return undefined
  }
}

const persistedStore = loadFromLocalStorage()

const sagaMiddleware = createSagaMiddleware()

const middleware = [sagaMiddleware]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, persistedStore, composeEnhancers(applyMiddleware(...middleware)))

store.subscribe(() => {
  //saveToLocalStorage(store.getState().auth)
})

sagaMiddleware.run(rootSaga)

export default store




