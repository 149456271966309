import React from 'react'
import { useTranslation } from 'react-i18next'

import MainLayout from '../partials/MainLayout'
import PageMetaTitle from '../../components/PageMetaTitle'
import Hero from './sections/Hero'
import UnderHero from './sections/UnderHero'
import Principles from './sections/Principles'
import Contact from './sections/Contact'

const AboutUs = () => {
  const { t } = useTranslation()

  return (
    <MainLayout>
      <PageMetaTitle title={t('mainMenu.about_us')} />
      <Hero />
      <UnderHero />
      <Principles />
      <Contact />
    </MainLayout>
  )
}

export default AboutUs
