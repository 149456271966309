import React from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { breakPoints, mainColors } from '../../../constants/constants'
import Page from '../../views/Page'
import { Col } from 'react-bootstrap'
import ContactForm from '../../../components/ContactForm'

import leftImage from '../../../assets/home/building-left.svg'
import rightImage from '../../../assets/home/building-right.svg'

const StyledSection = styled.section`
  background: ${mainColors.neonBlue};
  color: ${mainColors.white};
`

const StyledPage = styled(Page)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`

const Wrapper = styled(Col)`
  @media (min-width: ${breakPoints.sm}) {
    &:before {
      content: url(${leftImage});
      position: absolute;
      left: 12px;
      bottom: -6px;
    }
  
    &:after {
      content: url(${rightImage});
      position: absolute;
      right: 12px;
      bottom: -6px;
    }
  }
`

const StyledDiv = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  width: 100%;
  margin: 12% auto;
  padding-top: 110px;

  p {
    font-size: 18px;
    line-height: 30px;
    padding-top: 16px;
    padding-bottom: 50px;

    span {
      font-weight: 700;
    }
  }

  @media (max-width: ${breakPoints.sm}) {
    width: 100%;
  }
`

const ContactUs = () => {
  const { t } = useTranslation()

  return (
    <StyledSection id="contact">
      <StyledPage>
        <Wrapper>
          <StyledDiv>
            <h3>{t('home.contact.title')}</h3>
            <p>
              <Trans>{t('home.contact.subtitle')}</Trans>
            </p>
            <ContactForm />
          </StyledDiv>
        </Wrapper>
      </StyledPage>
    </StyledSection>
  )
}

export default ContactUs
