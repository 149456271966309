import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { closeModal, openModal } from '../../redux/actions/modal-custom'
import { useDispatch, useSelector } from 'react-redux'
import { actionIcons, mainColors, Role, tableKeys, api, QUERY_KEYS, TABLE_FONT_SIZE } from '../../constants/constants'
import ProjectLayout from './ProjectLayout'
import CustomLinkButton from '../../components/CustomLinkButton'
import { CustomCellWrapper, customSelect, DashBoardModalBody, DashBoardModalBodyWrapper, HeaderWrapper, Loading, StyledLoading } from '../../components/sharedStyles'
import CustomTable from '../../components/CustomTable'
import { fetchTableData, toggleActiveRow } from '../../redux/actions/table'
import { checkAccess, userId } from '../../components/helpers'
import DropZone from '../../components/DropZone'
import CustomTabs from '../../components/CustomTabs'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FormikInput } from '../../components/FormikInput'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Select from 'react-select'
import { useQuery, useMutation } from 'react-query'
import { ReactComponent as ArrowUp } from '../../assets/icons/arrow_drop_up.svg'
import { getTableDataApi, validateContractApi } from '../../redux/sagas/tablesSaga'

const CREATE_CONTRACT_KEY = "createContractIn"

const CustomCell = styled.span`
  padding: 1px 8px;
  border-radius: 4px;
  font-weight: 500;
  font-size: ${TABLE_FONT_SIZE};

  ${({ $color }) => $color === 'Ongoing' && css`
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2081FA;
    border: 1px solid #2081FA;
    color: #2081FA;
  `}

  ${({ $color }) => $color === 'Ended' && css`
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #002375;
    border: 1px solid #002375;
    color: #002375;
  `}
`

const SubmitWrapper = styled.div`
  width 100%;

  button {
    width: 100%;
    margin-left: 0 !important;
  }
`

const ModalBody = ({ documentRowId, data, onValidate }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  //add contract loading and replace isSubmitting
  const saving = useSelector(state => state.projects.create_project_loading)

  const body = [
    {
      id: 'supplier',
      type: 'text',
      label: 'Supplier',
      placeholder: 'supplier'
    },
    {
      id: 'contract_number',
      type: 'text',
      label: 'Contract Number',
      placeholder: '1234'
    },
    {
      id: 'contract_date',
      type: 'date',
      label: 'Contract Date',
      placeholder: '12 June, 2021'
    },
    {
      id: 'contract_description',
      type: 'text',
      label: 'Contract description',
      placeholder: 'Contract description'
    },
  ]

  const { mutate: validateContract, isLoading } = useMutation(validateContractApi, {
    onSuccess: (response) => {
      //trimite datele si la succes da delete la row-ul cu fisierul props.id, share functionality with DELETE_ROW contract
      dispatch(closeModal())
      onValidate('approved')
    }
  })

  return (
    <Formik
      initialValues={{
        supplier: "",
        contract_number: "",
        contract_date: "",
        contract_description: "",
      }}
      validationSchema={Yup.object({
        supplier: Yup.string()
          .max(50, "Must be 50 characters or less")
          .required(t('validations.required')),
        contract_number: Yup.string()
          .max(60, "Must be 60 characters or less")
          .required(t('validations.required')),
        contract_description: Yup.string()
          .max(60, "Must be 60 characters or less")
          .required(t('validations.required')),
      })}
      onSubmit={async (values) => {
        validateContract({ values, data })
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <DashBoardModalBody>
            {body.map(item => {
              if (item.type === 'date') {
                return (
                  <DashBoardModalBodyWrapper key={item.id}>
                    <label>
                      {item.label}
                      <span>*</span>
                    </label>
                    <DatePicker
                      selected={values.contract_date}
                      onChange={date => setFieldValue('contract_date', date)}
                      dateFormat="dd MMMM, yyyy"
                      className="modal-number-input"
                      name="startDate"
                      placeholderText="No date"
                    />
                  </DashBoardModalBodyWrapper>
                )
              }
              return (
                <FormikInput
                  key={item.id}
                  label={item.label}
                  name={item.id}
                  type={item.type}
                  placeholder={item.placeholder}
                  showIcon
                />
              )
            })}
          </DashBoardModalBody>
          <SubmitWrapper>
            <CustomLinkButton
              textColor={mainColors.white}
              innerColor={mainColors.neonBlue}
              borderColor={mainColors.neonBlue}
              text={isSubmitting || isLoading ? 'Validating...' : 'Validate Contract'}
              type='submit'
              button
            />
          </SubmitWrapper>
        </Form>
      )}
    </Formik>
  )
}

const GenericTabComponent = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const {
    table_key,
    project_id,
    cols,
    outsideFilter,
    hideHeaders,
    showPagination,
    activeRowId,
    toggleActiveRow,
    showStandardColumns,
    show_action_items,
    approve_action,
    show_doc_interaction,
    currency,
    size
  } = props

  useEffect(() => {
    //refactor since you don't need 2 requests here
    //dispatch(fetchTableData(table_key, project_id))
  }, [])

  const loading = useSelector(state => state.tables[table_key].loading)
  const tableData = useSelector(state => state.tables[table_key].data)

  return (
    <>
      {loading ? (
        <StyledLoading />
      ) : (
        <CustomTable
          table_key={table_key}
          project_id={project_id}
          table_data={tableData}
          cols={cols}
          outsideFilter={outsideFilter}
          hideHeaders={hideHeaders}
          showPagination={showPagination}
          activeRowId={activeRowId}
          toggleActiveRow={toggleActiveRow}
          showStandardColumns={showStandardColumns}
          show_action_items={show_action_items}
          approve_action={approve_action}
          show_doc_interaction={show_doc_interaction}
          ref={ref}
          currency={currency}
          size={size}
        />
      )}
    </>
  )
})

const Contracts = ({ className }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const addContractsRef = useRef()

  const activeRowId = useSelector(state => state.tables.budget_contracts_table.active_row)
  const loadingContracts = useSelector(state => state.tables.budget_contracts_table.loading)
  const contractsTableData = useSelector(state => state.tables.budget_contracts_table.data)

  const activeContractLineRowId = useSelector(state => state.tables.budget_contracts_lines_table.active_row)
  const loadingContractsLines = useSelector(state => state.tables.budget_contracts_lines_table.loading)
  const contractLinesTableData = useSelector(state => state.tables.budget_contracts_lines_table.data)

  const projectId = location.state?.id

  const [redirectAfterUpload, setRedirectAfterUpload] = useState(false)

  useEffect(() => {
    dispatch(fetchTableData(tableKeys.budget_contracts_table, projectId))
  }, [dispatch, projectId, redirectAfterUpload])

  useEffect(() => {
    dispatch(fetchTableData(tableKeys.budget_contracts_lines_table, projectId))
  }, [dispatch, projectId, redirectAfterUpload])

  const COLUMNS_CONTRACTS_PENDING = [
    {
      Header: 'Document',
      accessor: 'document_name',
      disableFilters: true,
      doNotHide: true,

      Cell: (tableProps) => <CustomCellWrapper>{tableProps.value}</CustomCellWrapper>
    },
    {
      Header: 'Upload Date',
      accessor: 'signing_date',
      componentType: 'date',
    },
  ]

  const COLUMNS_CONTRACTS_LINES = [
    {
      Header: 'Budget Line',
      accessor: 'id',
      disableFilters: true,
      doNotHide: true,

      Cell: (tableProps) => <CustomCellWrapper>{tableProps.value}</CustomCellWrapper>
    },
    {
      Header: 'Supplier',
      accessor: 'supplier',
    },
    {
      Header: 'Contract Number',
      accessor: 'contract_number',
    },
    {
      Header: 'Contract date',
      accessor: 'contract_date',
      componentType: 'date',
    },
    {
      Header: 'Contract object',
      accessor: 'contract_object'
    },
    {
      Header: 'Status',
      accessor: 'status',
      doNotHide: true,

      Cell: (tableProps) => (
        <CustomCellWrapper>
          <CustomCell $color={tableProps.value}>
            {t('document_status.' + (tableProps.value).toLowerCase())}
          </CustomCell>
        </CustomCellWrapper>
      ),
    },
  ]

  let roleBasedActions = [actionIcons.delete, actionIcons.approve]
  if (checkAccess([Role.monitor])) {
    roleBasedActions = [actionIcons.download, actionIcons.reject, actionIcons.approve]
  }

  const comments = {
    Header: 'Comments',
    accessor: 'Comments',
  }
  const COLUMNS_CONTRACTS_REJECTED = [...COLUMNS_CONTRACTS_PENDING, comments]

  const tabs = [
    {
      eventKey: 'under_review',
      title: t('tabs.under_review'),
      Component: (
        <GenericTabComponent
          table_key={tableKeys.budget_contracts_table}
          project_id={projectId}
          cols={COLUMNS_CONTRACTS_PENDING}
          activeRowId={activeRowId}
          showStandardColumns
          show_action_items={roleBasedActions}
          approve_action={(id, data) => dispatch(openModal(ModalContainer(id, data)))}
          showPagination={false}
          toggleActiveRow={toggleActiveRow}
          size='2fr 2.5fr 1fr'
        />
      )
    },
    {
      eventKey: 'approved',
      title: t('tabs.approved'),
      Component: (
        <GenericTabComponent
          ref={addContractsRef}
          table_key={tableKeys.budget_contracts_lines_table}
          project_id={projectId}
          cols={COLUMNS_CONTRACTS_LINES}
          showStandardColumns={false}
          activeRowId={activeContractLineRowId}
          showPagination={false}
          toggleActiveRow={toggleActiveRow}
        />
      )
    },
    {
      eventKey: 'rejected',
      title: t('tabs.rejected'),
      Component: (
        <GenericTabComponent
          table_key={tableKeys.budget_contracts_table}
          project_id={projectId}
          cols={COLUMNS_CONTRACTS_REJECTED}
          activeRowId={activeRowId}
          showStandardColumns={false}
          showPagination={false}
          toggleActiveRow={toggleActiveRow}
          size='2fr 2.5fr 1fr'
        />
      )
    }
  ]

  const defaultTab = 'under_review'
  const [tabKey, setTabKey] = useState(defaultTab)
  const activeTabkey = key => setTabKey(key)

  const ModalContainer = (id, data) => ({
    title: 'Validate Contract',
    body: <ModalBody onValidate={activeTabkey} documentRowId={id} data={data} />,
    showFooter: false,
    showClose: true
  })

  const { isLoading: loadingBudgetLines, data: budgetLines } = useQuery(
    [QUERY_KEYS.budget_lines, userId()],
    () => getTableDataApi(tableKeys.budget_lines_table, projectId),
    {
      enabled: true
    }
  )
  const [budgetItemId, setBudgetItemId] = useState(null)

  const AddContractModalBody = () => {
    const [itemId, setItemId] = useState(null)
    return (
      <>
        {loadingBudgetLines ? (
          <Loading />
        ) : (
          <Select
            styles={customSelect}
            maxWidth='252px'
            options={budgetLines?.object_list}
            getOptionLabel={e => e.label}
            getOptionValue={e => e.id}
            onChange={option => setItemId(option.id)}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => <ArrowUp />
            }}
            placeholder="Please select a budget line"
          />
        )}
        {itemId && (
          <DropZone
            setRedirectAfterUpload={setRedirectAfterUpload}
            items='contracts'
            subtext='Upload your first contract right now'
            url={api.budget_base + api.budget_documents + api.contracts}
            method="POST"
            params={{
              [CREATE_CONTRACT_KEY]: {
                "budget_line": itemId,
                "project_id": projectId,
                "owner_id": userId(),
              }
            }}
            params_key={CREATE_CONTRACT_KEY}
          />
        )}
      </>
    )
  }

  const AddContractContainer = {
    title: 'Add Contract',
    body: <AddContractModalBody />,
    showFooter: false,
    showClose: true
  }

  return (
    <ProjectLayout
      header={(
        <HeaderWrapper>
          <h2>{t('project.routes.contracts')}</h2>
        </HeaderWrapper>
      )}
      footer={
        tabKey === 'under_review' && !(typeof contractsTableData === 'undefined' || contractsTableData.length < 1) && !redirectAfterUpload && (
          <CustomLinkButton
            type="button"
            onClick={() => dispatch(openModal(AddContractContainer))}
            textColor={mainColors.white}
            innerColor={mainColors.neonBlue}
            borderColor={mainColors.neonBlue}
            text={t('project.contracts.add_contract')}
            button
          />
        )
      }
      footerAlignment="flex-end"
    >
      {checkAccess([Role.admin, Role.dev, Role.monitor]) && (
        ((typeof contractsTableData === 'undefined' || contractsTableData.length < 1) && (typeof contractLinesTableData === 'undefined' || contractLinesTableData?.length < 1) && !redirectAfterUpload ? (
          <>
            {loadingBudgetLines ? (
              <Loading />
            ) : (
              <Select
                styles={customSelect}
                maxWidth='252px'
                options={budgetLines?.object_list}
                getOptionLabel={e => e.label}
                getOptionValue={e => e.id}
                onChange={option => setBudgetItemId(option.id)}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => <ArrowUp />
                }}
                placeholder="Please select a budget line"
              />
            )}
            {budgetItemId && (
              <DropZone
                setRedirectAfterUpload={setRedirectAfterUpload}
                items='contracts'
                subtext='Upload your first contract right now'
                url={api.budget_base + api.budget_documents + api.contracts}
                method="POST"
                params={{
                  [CREATE_CONTRACT_KEY]: {
                    "budget_line": budgetItemId,
                    "project_id": projectId,
                    "owner_id": userId(),
                  }
                }}
                params_key={CREATE_CONTRACT_KEY}
              />
            )}
          </>
        ) : (
          <div>
            {loadingContracts ? (
              <Loading />
            ) : (
              <CustomTabs
                key={tabKey}
                id="custom-contracts-tabs"
                tabs={tabs}
                defaultKey={tabKey}
                getKey={(k) => activeTabkey(k)}
              />
            )
            }
          </div>
        ))
      )}
    </ProjectLayout>
  )
}

export default Contracts
