import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { mainColors, breakPoints } from '../../../constants/constants'
import Page from '../../views/Page'
import { Col } from 'react-bootstrap'

import arrowUp from '../../../assets/home/arrow-bendright-up.svg'

const monthly = [
  {
    price: '$20',
    duration: '/month',
    type: 'Starter',
    list: [
      'All limited links',
      'Own analytics platform',
      'Chat support',
      'Optimize hashtags',
      'Unlimited users'
    ]
  },
  {
    price: '$20',
    duration: '/month',
    type: 'Starter',
    list: [
      'All limited links',
      'Own analytics platform',
      'Chat support',
      'Optimize hashtags',
      'Unlimited users'
    ]
  },
  {
    price: '$20',
    duration: '/month',
    type: 'Starter',
    list: [
      'All limited links',
      'Own analytics platform',
      'Chat support',
      'Optimize hashtags',
      'Unlimited users'
    ]
  },
]

const yearly = [
  {
    price: '$40',
    duration: '/year',
    type: 'Business',
    list: [
      'All limited links',
      'Own analytics platform',
      'Chat support',
      'Optimize hashtags',
      'Unlimited users'
    ]
  },
  {
    price: '$40',
    duration: '/year',
    type: 'Business',
    list: [
      'All limited links',
      'Own analytics platform',
      'Chat support',
      'Optimize hashtags',
      'Unlimited users'
    ]
  },
  {
    price: '$40',
    duration: '/year',
    type: 'Business',
    list: [
      'All limited links',
      'Own analytics platform',
      'Chat support',
      'Optimize hashtags',
      'Unlimited users'
    ]
  },
]

const StyledSection = styled.section`
  background: ${mainColors.white};
  min-height: 600px;
`

const StyledPage = styled(Page)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 180px;
  padding-bottom: 180px;

  @media (max-width: ${breakPoints.sm}) {
    padding-top: 12%;
    padding-bottom: 12%;
  }

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  h2 {
    font-weight: 700;
    font-size: 44px;
    line-height: 51px;
    color: ${mainColors.lighterGray};
    padding-bottom: 24px;
  }

  h3 {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.2px;
    color: ${mainColors.darkGray};
    font-weight: 400;
  }
`

const LeftCol = styled(Col)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 117px;

  @media (max-width: ${breakPoints.sm}) {
    padding-left: 0;
    align-items: center;
  }
`

const RightCol = styled(Col)`
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${breakPoints.sm}) {
    margin-top: 25px;
    justify-content: center;
    margin-top: 50px;
  }
`

const Switch = styled.div`
  .switch {
    position: relative;
    background: #ECECEC;
    border-radius: 22px;
    width: 220px;
    height: 44px;
    display: flex;
    align-items: center;
    margin-right: 117px;
    margin-top: 20px;

    @media (max-width: ${breakPoints.sm}) {
      margin-right: 0;
      margin-top: 0;
    }

    &__label {
      display: inline-block;
      padding: 10px 29px;
      text-align: center;
      cursor: pointer;
      transition: color 200ms ease-out;
      z-index: 1;
      position: relative;
      text-transform: uppercase;
      display: inline;
      font-weight: bold;
      font-size: 10px;
      line-height: 11px;
      text-align: center;
      letter-spacing: 0.833333px;
    }
    &__indicator {
      width: 110px;
      height: 44px;
      position: absolute;
      left: 0;
      background: ${mainColors.neonBlue};
      border-radius: 22px;
      transition: transform 600ms cubic-bezier(.02,.94,.09,.97),
                  background 300ms cubic-bezier(.17,.67,.14,1.03);
      transform: translate3d(0,0,0);
    }

    input + label {
      color: #515058;
    }

    input:checked + label {
      color: ${mainColors.white};
    }

    input#one:checked ~ .switch__indicator {
      background: ${mainColors.neonBlue};
      transform: translate3d(0,0,0);
    }
    input#two:checked ~ .switch__indicator {
      background: ${mainColors.neonBlue};
      transform: translate3d(110px,0,0);
    }
    input[type="radio"] {
      &:not(:checked),
      &:checked {
        display: none;
      }
    }
  }

  .price {
    color: #F19F1B;
    font-size: 18px;
    line-height: 46px;
    margin-top: 10px;
    margin-right: 95px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: ${breakPoints.sm}) {
      margin-right: 0;
    }

    span {
      font-weight: 700;
      padding-left: 5px;

      &:after {
        content: url(${arrowUp});
      }
    }
  }
`

const StyledCol = styled(Col)`
  padding-top: 80px;
  padding-left: 117px;
  padding-right: 117px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: ${breakPoints.sm}) {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }
`

const Badge = styled.div`
  margin-right: 12px;

  &:first-child {
    margin-left: 30px;
  }

  @media (max-width: ${breakPoints.sm}) {
    margin-right: 0;

    &:first-child {
      margin-left: 0;
    }
  }
`

const Wrap = styled.div`
  background: ${mainColors.darkBlue};
  color: ${mainColors.white};
  width: 300px;
  padding-right: 26px;

  @media (max-width: ${breakPoints.sm}) {
    width: 100%;
  }

  h3 {
    color: ${mainColors.white};
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 30px 27px;
  }

  ul {
    margin: 0;
    padding: 0;
  }
`

const PricingWrapper = styled.div`
  background: ${mainColors.white};
  padding-left: 5px;
  padding-bottom: 65px;
`

const Price = styled.div`
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  color: #515058;

  span {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #515058;
    padding-left: 8px;
    margin-bottom: 5px;
  }
`

const Starter = styled.div`
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  color: #515058;
  padding-top: 20px;
`

const Options = styled.div`
  li {
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    color: #515058;
    list-style-type: none;
    padding-bottom: 23px;
  }
`

const Separator = styled.div`
  background: #ECECEC;
  height: 1px;
  max-width: 230px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 26px;
`

const Pricing = () => {
  const { t } = useTranslation()
  const [billing, setBilling] = useState(true)

  const badges = (data) => (
    data.map((item, index) => (
      <Badge key={index}>
        <Wrap>
          <PricingWrapper>
            <Price>
              <div>
                {item.price}<span>{item.duration}</span>
              </div>
              <Starter>{item.type}</Starter>
            </Price>
            <Separator />
            <Options>
              <ul>
                {item.list.map((p, i) => (
                  <li key={i}>{p}</li>
                ))}
              </ul>
            </Options>
          </PricingWrapper>
          <h3>Choose plan</h3>
        </Wrap>
      </Badge>
    ))
  )

  return (
    <StyledSection>
      <StyledPage>
        <LeftCol md={7} sm={12} xs={12}>
          <h2>{t('home.pricing.title')}</h2>
          <h3>{t('home.pricing.subtitle')}</h3>
        </LeftCol>
        <RightCol md={5} sm={12} xs={12}>
          <Switch>
            <div className="switch">
              <input name="switch" id="one" type="radio" onClick={() => setBilling(true)} defaultChecked />
              <label htmlFor="one" className="switch__label">{t('home.pricing.monthly')}</label>
              <input name="switch" id="two" type="radio" onClick={() => setBilling(false)} />
              <label htmlFor="two" className="switch__label">{t('home.pricing.yearly')}</label>
              <div className="switch__indicator"></div>
            </div>
            <div className="price">
              {t('home.pricing.save')}
              <span>{t('home.pricing.percent')}</span>
            </div>
          </Switch>
        </RightCol>
        <StyledCol md={12}>
          {billing ? (
            badges(monthly)
          ) : (
            badges(yearly)
          )
          }
        </StyledCol>
      </StyledPage>
    </StyledSection>
  )
}

export default Pricing
