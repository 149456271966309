import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { mainColors } from '../../constants/constants'
import ProjectLayout from './ProjectLayout'
import CustomLinkButton from '../../components/CustomLinkButton'
import { HeaderWrapper } from '../../components/sharedStyles'

const MonitoringReports = () => {
  const { t } = useTranslation()

  return (
    <ProjectLayout
      header={(
        <HeaderWrapper>
          <h2>{t('project.routes.monitoring_reports')}</h2>
        </HeaderWrapper>
      )}
    >

    </ProjectLayout>
  )
}

export default MonitoringReports
