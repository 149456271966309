import React, { forwardRef, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { actionIcons, api, currencySymbols, mainColors, QUERY_KEYS, Role, tableKeys, TABLE_FONT_SIZE } from '../../constants/constants'
import ProjectLayout from './ProjectLayout'
import CustomLinkButton from '../../components/CustomLinkButton'
import CustomTable, { defaultColumns } from '../../components/CustomTable'
import { CustomCellWrapper, customSelect, DashBoardModalBody, DashBoardModalBodyWrapper, HeaderWrapper, Loading, StyledLoading, StyledSearchInput } from '../../components/sharedStyles'
import { fetchTableData, toggleActiveRow } from '../../redux/actions/table'
import CustomTableFilters from '../../components/CustomTableFilters'
import DropZone from '../../components/DropZone'
import { checkAccess, formatNumber, userId } from '../../components/helpers'
import CustomTabs from '../../components/CustomTabs'
import { getTableDataApi, validateInvoiceApi } from '../../redux/sagas/tablesSaga'
import { useQuery, useMutation } from 'react-query'
import Select from 'react-select'
import { closeModal, openModal } from '../../redux/actions/modal-custom'
import { ReactComponent as ArrowUp } from '../../assets/icons/arrow_drop_up.svg'

import NumberFormat from 'react-number-format'
import DatePicker from 'react-datepicker'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FormikInput } from '../../components/FormikInput'

const SubmitWrapper = styled.div`
  width 100%;

  button {
    width: 100%;
    margin-left: 0 !important;
  }
`

const UploadWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(32, 129, 250, 0.9);
  z-index: 2;

  .fine-uploader-dropzone-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    flex-direction: column;
  }
`
const CustomCell = styled.span`
  padding: 1px 8px;
  border-radius: 4px;
  font-weight: 500;
  font-size: ${TABLE_FONT_SIZE};

  ${({ $color }) => $color === 'Ongoing' && css`
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2081FA;
    border: 1px solid #2081FA;
    color: #2081FA;
  `}

  ${({ $color }) => $color === 'Ended' && css`
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #002375;
    border: 1px solid #002375;
    color: #002375;
  `}
`

const CREATE_INVOICE_KEY = "createInvoiceIn"

const inputStyle = {
  borderWidth: 0,
  color: "#000"
}

const OCRModalBody = ({ invoice_id, currency }) => {
  const { isLoading, data } = useQuery(
    ["ocr_details", userId()],
    () => getTableDataApi("ocr_details", invoice_id),
    {
      enabled: true,
    },
  )

  if (data?.error && !isLoading) return <div>{data.error}</div>
  return (
    isLoading ? (
      <Loading />
    ) : (
      <div>
        <ul>
          <li>Amount: <NumberFormat disabled style={inputStyle} prefix={currency} thousandSeparator value={data.amount} /></li>
          <li>Amount due: <NumberFormat disabled style={inputStyle} prefix={currency} thousandSeparator value={data.amount_due} /></li>
          <li>Amount with vat: <NumberFormat disabled style={inputStyle} prefix={currency} thousandSeparator value={data.amount_with_vat} /></li>
          <li>Description: {data.description}</li>
          <li>Exchange rate: {data.exchange_rate}</li>
          <li>Invoice No: {data.invoice_number}</li>
          <li><div style={{ display: "flex" }}>
            <div style={{ width: "140px "}}>Invoicing date:</div>
            <DatePicker
              selected={new Date(data.invoicing_date)}
              dateFormat="dd MMMM, yyyy"
              disabled
              placeholderText="No date"
              className="date-picker-data"
            />
          </div>
          </li>
          <li>Paid amount: <NumberFormat disabled style={inputStyle} prefix={currency} thousandSeparator value={data.paid_amount} /></li>
          <li><div style={{ display: "flex" }}>
            <div style={{ width: "138px "}}>Payment date:</div>
            <DatePicker
              selected={new Date(data.payment_date)}
              dateFormat="dd MMMM, yyyy"
              disabled
              placeholderText="No date"
              className="date-picker-data"
            />
          </div>
          </li>
          <li>VAT: {data.vat}%</li>
          <li>Vendor: {data.vendor}</li>
        </ul>
      </div>
    )
  )
}

const ModalBody = ({ data, onValidate }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  //add contract loading and replace isSubmitting
  const saving = useSelector(state => state.projects.create_project_loading)

  const body = [
    {
      id: 'supplier',
      type: 'text',
      label: 'Supplier',
      placeholder: 'supplier'
    },
    {
      id: 'contract_number',
      type: 'text',
      label: 'Contract Number',
      placeholder: '1234'
    },
    {
      id: 'contract_date',
      type: 'date',
      label: 'Contract Date',
      placeholder: '12 June, 2021'
    },
    {
      id: 'contract_description',
      type: 'text',
      label: 'Contract Description',
      placeholder: 'Enter description'
    },
  ]

  const { mutate: validateContract, isLoading } = useMutation(validateInvoiceApi, {
    onSuccess: (response) => {
      //trimite datele si la succes da delete la row-ul cu fisierul props.id, share functionality with DELETE_ROW contract
      dispatch(closeModal())
      onValidate('approved')
    }
  })

  return (
    <Formik
      initialValues={{
        supplier: "",
        contract_number: "",
        contract_date: "",
        contract_description: "",
      }}
      validationSchema={Yup.object({
        supplier: Yup.string()
          .max(50, "Must be 50 characters or less")
          .required(t('validations.required')),
        contract_number: Yup.string()
          .max(60, "Must be 60 characters or less")
          .required(t('validations.required')),
        contract_description: Yup.string()
          .max(60, "Must be 60 characters or less")
          .required(t('validations.required')),
      })}
      onSubmit={async (values) => {
        validateContract({ values, data })
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <DashBoardModalBody>
            {body.map(item => {
              if (item.type === 'date') {
                return (
                  <DashBoardModalBodyWrapper key={item.id}>
                    <label>
                      {item.label}
                      <span>*</span>
                    </label>
                    <DatePicker
                      selected={values.contract_date}
                      onChange={date => setFieldValue('contract_date', date)}
                      dateFormat="dd MMMM, yyyy"
                      className="modal-number-input"
                      name="startDate"
                      placeholderText="No date"
                    />
                  </DashBoardModalBodyWrapper>
                )
              }
              return (
                <FormikInput
                  key={item.id}
                  label={item.label}
                  name={item.id}
                  type={item.type}
                  placeholder={item.placeholder}
                  showIcon
                />
              )
            })}
          </DashBoardModalBody>
          <SubmitWrapper>
            <CustomLinkButton
              textColor={mainColors.white}
              innerColor={mainColors.neonBlue}
              borderColor={mainColors.neonBlue}
              text={isSubmitting || isLoading ? 'Validating...' : 'Validate invoice'}
              type='submit'
              button
            />
          </SubmitWrapper>
        </Form>
      )}
    </Formik>
  )
}

const GenericTabComponent = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const {
    table_key,
    project_id,
    cols,
    outsideFilter,
    hideHeaders,
    showPagination,
    activeRowId,
    toggleActiveRow,
    showStandardColumns,
    show_action_items,
    approve_action,
    show_doc_interaction,
    currency,
    size,
    onOcr
  } = props

  useEffect(() => {
    //refactor since you don't need 2 requests here
    //dispatch(fetchTableData(table_key, project_id))
  }, [])

  const loading = useSelector(state => state.tables[table_key].loading)
  const tableData = useSelector(state => state.tables[table_key].data)

  return (
    <>
      {loading ? (
        <StyledLoading />
      ) : (
        <CustomTable
          table_key={table_key}
          project_id={project_id}
          table_data={tableData}
          cols={cols}
          outsideFilter={outsideFilter}
          hideHeaders={hideHeaders}
          showPagination={showPagination}
          activeRowId={activeRowId}
          toggleActiveRow={toggleActiveRow}
          showStandardColumns={showStandardColumns}
          show_action_items={show_action_items}
          approve_action={approve_action}
          show_doc_interaction={show_doc_interaction}
          ref={ref}
          currency={currency}
          size={size}
          onOcr={onOcr}
        />
      )}
    </>
  )
})

const Invoices = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const addInvoicesRef = useRef()

  const toggleTableHeaders = useSelector(state => state.tables.budget_invoices_table)
  const projectId = location.state?.id
  const details = useSelector(state => state.projects.project_details)

  const activeRowId = useSelector(state => state.tables.budget_invoices_table.active_row)
  const loadingInvoices = useSelector(state => state.tables.budget_invoices_table.loading)
  const invoicesTableData = useSelector(state => state.tables.budget_invoices_table.data)

  const activeInvoiceLineRowId = useSelector(state => state.tables.budget_invoices_lines_table.active_row)
  const loadingInvoicesLines = useSelector(state => state.tables.budget_invoices_lines_table.loading)
  const invoiceLinesTableData = useSelector(state => state.tables.budget_invoices_lines_table.data)

  const [showUpload, setShowUpload] = useState(false)
  const [redirectAfterUpload, setRedirectAfterUpload] = useState(false)

  useEffect(() => {
    dispatch(fetchTableData(tableKeys.budget_invoices_table, projectId))
  }, [dispatch, projectId])

  useEffect(() => {
    dispatch(fetchTableData(tableKeys.budget_invoices_lines_table, projectId))
  }, [dispatch, projectId, redirectAfterUpload])

  const COLUMNS_INVOICES_PENDING = [
    {
      Header: 'Document',
      Footer: 'Document',
      accessor: 'document_name',
      disableFilters: true,
      doNotHide: true,

      Cell: (tableProps) => <CustomCellWrapper>{tableProps.value}</CustomCellWrapper>
    },
    {
      Header: 'Upload Date',
      Footer: 'Upload Date',
      accessor: 'uploaded_at',

      Cell: (tableProps) => {
        const event = new Date(tableProps.value)
        const options = { year: 'numeric', month: 'long', day: 'numeric' }
        const date = event.toLocaleDateString('en-GB', options)
        return (
          <CustomCellWrapper>
            <CustomCell>
              {date}
            </CustomCell>
          </CustomCellWrapper>
        )
      }
    },
  ]

  const COLUMNS_INVOICES_LINES = [
    {
      Header: 'Budget Line',
      accessor: 'budget_category_id',
      disableFilters: true,
      doNotHide: true,

      Cell: (tableProps) => <CustomCellWrapper>{tableProps.value}</CustomCellWrapper>
    },
    {
      Header: 'Invoice Date',
      accessor: 'invoicing_date',
      componentType: 'date'
    },
    {
      Header: 'Vendor',
      accessor: 'vendor',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      componentType: 'inputNumber',
      disableFilters: true,
      doNotHide: true
    }
  ]

  let roleBasedActions = [actionIcons.delete, actionIcons.approve]
  if (checkAccess([Role.monitor])) {
    roleBasedActions = [actionIcons.download, actionIcons.reject, actionIcons.approve]
  }

  let approvedRoleBasedActions = [actionIcons.ocr]

  const comments = {
    Header: 'Comments',
    accessor: 'Comments',
  }
  const COLUMNS_INVOICES_REJECTED = [...COLUMNS_INVOICES_PENDING, comments]

  const onOcr = (invoice_id) => {
    dispatch(openModal(OCRModalContainer(invoice_id, currencySymbols[details?.currency])))
  }

  const tabs = [
    {
      eventKey: 'under_review',
      title: t('tabs.under_review'),
      Component: (
        <GenericTabComponent
          table_key={tableKeys.budget_invoices_table}
          project_id={projectId}
          cols={COLUMNS_INVOICES_PENDING}
          activeRowId={activeRowId}
          showStandardColumns
          show_action_items={roleBasedActions}
          approve_action={(id, data) => dispatch(openModal(ModalContainer(id, data)))}
          showPagination={false}
          toggleActiveRow={toggleActiveRow}
          currency={currencySymbols[details?.currency]}
          size='2fr 2.5fr 1fr'
        />
      )
    },
    {
      eventKey: 'approved',
      title: t('tabs.approved'),
      Component: (
        <GenericTabComponent
          ref={addInvoicesRef}
          table_key={tableKeys.budget_invoices_lines_table}
          project_id={projectId}
          cols={COLUMNS_INVOICES_LINES}
          showStandardColumns
          show_action_items={approvedRoleBasedActions}
          activeRowId={activeInvoiceLineRowId}
          showPagination={false}
          toggleActiveRow={toggleActiveRow}
          currency={currencySymbols[details?.currency]}
          onOcr={onOcr}
        />
      )
    },
    {
      eventKey: 'rejected',
      title: t('tabs.rejected'),
      Component: (
        <GenericTabComponent
          table_key={tableKeys.budget_invoices_table}
          project_id={projectId}
          cols={COLUMNS_INVOICES_REJECTED}
          activeRowId={activeRowId}
          showStandardColumns={false}
          showPagination={false}
          toggleActiveRow={toggleActiveRow}
          size='2fr 2.5fr 1fr'
        />
      )
    }
  ]

  const defaultTab = 'under_review'
  const [tabKey, setTabKey] = useState(defaultTab)
  const activeTabkey = key => setTabKey(key)

  const ModalContainer = (id, data) => ({
    title: 'Validate invoice',
    body: <ModalBody onValidate={activeTabkey} data={data} />,
    showFooter: false,
    showClose: true
  })

  const OCRModalContainer = (invoice_id, currency) => ({
    title: 'Show invoice details',
    body: <OCRModalBody invoice_id={invoice_id} currency={currency} />,
    showFooter: false,
    showClose: true
  })

  const { isLoading: loadingBudgetLines, data: budgetLines } = useQuery(
    [QUERY_KEYS.budget_lines, userId()],
    () => getTableDataApi(tableKeys.budget_lines_table, projectId),
    {
      enabled: true
    }
  )
  const [budgetItemId, setBudgetItemId] = useState(null)

  const AddInvoiceModalBody = () => {
    const [itemId, setItemId] = useState(null)
    return (
      <>
        {loadingBudgetLines ? (
          <Loading />
        ) : (
          <Select
            styles={customSelect}
            maxWidth='252px'
            options={budgetLines?.object_list}
            getOptionLabel={e => e.label}
            getOptionValue={e => e.id}
            onChange={option => setItemId(option.id)}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => <ArrowUp />
            }}
            placeholder="Please select a budget line"
          />
        )}
        {itemId && (
          <DropZone
            setRedirectAfterUpload={setRedirectAfterUpload}
            items='invoices'
            subtext='Upload your first invoice right now'
            url={api.budget_base + api.budget_documents + api.invoices}
            method="POST"
            params={{
              [CREATE_INVOICE_KEY]: {
                "budget_line": itemId,
                "project_id": projectId,
                "owner_id": userId(),
              }
            }}
            params_key={CREATE_INVOICE_KEY}
          />
        )}
      </>
    )
  }

  const AddInvoiceContainer = {
    title: 'Add Invoice',
    body: <AddInvoiceModalBody />,
    showFooter: false,
    showClose: true
  }

  const [outsideFilter, setOutsideFilter] = useState('')

  return (
    <ProjectLayout
      header={
        <HeaderWrapper>
          <h2>{t('project.routes.invoices')}</h2>
        </HeaderWrapper>
      }
      footer={
        tabKey === 'under_review' && !(typeof invoicesTableData === 'undefined' || invoicesTableData.length < 1) && !redirectAfterUpload && (
          <CustomLinkButton
            type="button"
            onClick={() => dispatch(openModal(AddInvoiceContainer))}
            textColor={mainColors.white}
            innerColor={mainColors.neonBlue}
            borderColor={mainColors.neonBlue}
            text={t('project.invoices.add_invoice')}
            button
          />
        )
      }
      footerAlignment="flex-end"
    >
      {checkAccess([Role.admin, Role.dev, Role.monitor]) && (
        ((typeof invoicesTableData === 'undefined' || invoicesTableData.length < 1) && (typeof invoiceLinesTableData === 'undefined' || invoiceLinesTableData?.length < 1) && !redirectAfterUpload ? (
          <>
            {loadingBudgetLines ? (
              <Loading />
            ) : (
              <Select
                styles={customSelect}
                maxWidth='252px'
                options={budgetLines?.object_list}
                getOptionLabel={e => e.label}
                getOptionValue={e => e.id}
                onChange={option => setBudgetItemId(option.id)}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => <ArrowUp />
                }}
                placeholder="Please select a budget line"
              />
            )}
            {budgetItemId && (
              <DropZone
                setRedirectAfterUpload={setRedirectAfterUpload}
                items='invoices'
                subtext='Upload your first invoice right now'
                url={api.budget_base + api.budget_documents + api.invoices}
                method="POST"
                params={{
                  [CREATE_INVOICE_KEY]: {
                    "budget_line": budgetItemId,
                    "project_id": projectId,
                    "owner_id": userId(),
                  }
                }}
                params_key={CREATE_INVOICE_KEY}
              />
            )}
          </>
        ) : (
          <div>
            {loadingInvoices ? (
              <Loading />
            ) : (
              <CustomTabs
                key={tabKey}
                id="custom-invoices-tabs"
                tabs={tabs}
                defaultKey={tabKey}
                getKey={(k) => activeTabkey(k)}
              />
            )}
          </div>
        ))
      )}
    </ProjectLayout>
  )
}

export default Invoices
