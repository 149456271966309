import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Checkbox } from './Checkbox'
import { addTableHeader, removeTableHeader, toggleTableColumns } from '../redux/actions/table'
import { closeModal, openModal } from '../redux/actions/modal-custom'

import { ReactComponent as FunnelSimple } from '../assets/icons/funnel_simple.svg'
import CustomLinkButton from './CustomLinkButton'
import { mainColors } from '../constants/constants'

const ModalWrapper = styled.div`
  .checkbox-wrapper {
    margin-bottom: 10px;
  }

  .filters-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-left: -35px;
    margin-right: -35px;
    padding-top: 24px;
    border-top: 1px solid #C2C9D1;
    padding-left: 20px;
    padding-right: 20px;

    button {
      width: 120px;
      padding: 4px 0;
    }
  }
`

const CustomTableFilters = ({
  columns,
  headers,
  table_key
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return (
    <button
      onClick={() => {
        const ModalContainer = ({
          title: t('table.customize_columns'),
          className: "table-modal-filter",
          showClose: false,
          body: (
            <ModalWrapper>
              {columns.map((column, i) => {
                if (column.doNotHide === true) return false

                return (
                  <div key={i} className="checkbox-wrapper">
                    <Checkbox
                      type='checkbox'
                      id={column.accessor}
                      defaultChecked={headers.includes(column.accessor)}
                      onChange={(e) => {
                        const value = e.target.id
                        const checked = e.target.checked
                        if (checked) {
                          dispatch(addTableHeader(value, table_key))
                        } else {
                          dispatch(removeTableHeader(value, table_key))
                        }

                        //localStorage.setItem('budget-table', arr)
                      }}
                      label={column.Header}
                    />
                  </div>
                )
              })}
              <div className="filters-buttons-wrapper">
                <CustomLinkButton
                  type="button"
                  onClick={() => {
                    dispatch(closeModal())
                  }}
                  textColor="#A3AED0"
                  innerColor="transparent"
                  borderColor="#A3AED0"
                  text={t('table.cancel')}
                  button
                />
                <CustomLinkButton
                  type="button"
                  onClick={() => {
                    dispatch(toggleTableColumns(table_key))
                    dispatch(closeModal())
                  }}
                  textColor={mainColors.white}
                  innerColor={mainColors.neonBlue}
                  borderColor={mainColors.neonBlue}
                  text={t('table.save')}
                  button
                />
              </div>
            </ModalWrapper>
          ),
          showFooter: false
        })
        dispatch(openModal(ModalContainer))
      }}
    >
      <FunnelSimple />
      {t('table.edit_table')}
    </button>
  )
}

export default CustomTableFilters

CustomTableFilters.propTypes = {
  columns: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  table_key: PropTypes.string.isRequired
}
