import { call, put, takeLatest } from 'redux-saga/effects'
import { localToken } from '../../components/helpers'
import { api, headers, TOKEN_TYPE } from '../../constants/constants'

const loginUrl = api.base + api.login
const userNameUrl = api.base + api.users + "/byUsername?userName="

function getLogin(username, password) {
  return fetch(loginUrl, {
    method: 'POST',
    headers,
    body: JSON.stringify({ username, password })
  }).then(response => response.json())
    .catch((error) => { throw error })
}

function getUserName(username) {
  return fetch(userNameUrl + username, {
    method: 'GET',
    headers: {
      ...headers,
      'Authorization': TOKEN_TYPE + ' ' + localToken()
    },
  }).then(response => response.json())
    .catch((error) => { throw error })
}

function* fetchUser({ username, password }) {
  try {
    const { token, message, status } = yield call(() => getLogin(username, password))

    if (token && !status) {
      const state = {
        isAuth: token,
      }
      localStorage.setItem('state', '{"auth":' + JSON.stringify(state) + '}')

      try {
        const userData = yield call(() => getUserName(username))
        const userState = {
          ...state,
          userData
        }

        localStorage.setItem('state', '{"auth":' + JSON.stringify(userState) + '}')
        yield put({ type: 'AUTH_SUCCESS', auth: token, userData: userData })

      } catch (e) {
        yield put({ type: 'AUTH_FAILED', message: e.message })
      }

    } else {
      yield put({ type: 'AUTH_FAILED', message: message })
    }

  } catch (e) {
    yield put({ type: 'AUTH_FAILED', message: e.message })
  }
}

function* authSaga() {
  yield takeLatest('AUTH_REQUESTED', fetchUser)
}

export default authSaga
