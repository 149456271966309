import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PageMetaTitle from '../components/PageMetaTitle'
import DashLayout from './partials/DashLayout'

import notFound from '../assets/404/frame.png'

const MainSection = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 15px;
  text-align: center;

  h2 {
    font-size: 24px;
    line-height: 28px;
    color: #AAB2C8;
    margin-top: 30px;
  }
`

const NotFound404 = () => {
  const { t } = useTranslation()
  const isAuth = useSelector(state => state.auth.isAuth)

  const body = (
    <>
      <img className='img-fluid' src={notFound} alt='Not found' />
      <h2>{t('404.not_found')}</h2>
    </>
  )

  if (isAuth) {
    return (
      <DashLayout>
        <PageMetaTitle title='404' />
        <MainSection>
          {body}
        </MainSection>
      </DashLayout>
    )
  }

  return (
    <MainSection>
      {body}
    </MainSection>
  )
}

export default NotFound404

