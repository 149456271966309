import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mainColors } from '../constants/constants'

const Ltc = styled.div`
  background: ${mainColors.blue};
  border-radius: 20px;
  max-width: 126px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${mainColors.white};
  font-size: 20px;

  .ltc- {
    &circle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: ${mainColors.blue};
      border: 4px solid #0E4FA0;
      margin-left: 6px;
    }
    &text {
      margin-left: 8px;
      margin-right: 13px;
    }
    &percent {
      font-weight: 700;
    }
  }
`

const LtcBadge = (props) => {
  const { percent, className } = props

  return (
    <Ltc className={className}>
      <div className="ltc-circle" />
      <div className="ltc-text">LTC</div>
      <div className="ltc-percent">{percent}%</div>
    </Ltc>
  )
}

export default LtcBadge

LtcBadge.propTypes = {
  percent: PropTypes.number.isRequired
}
