import * as type from '../types'

const initialState = {
  show: false,
  data: [],
}

export default function modal(state = initialState, action) {
  switch(action.type) {
    case type.OPEN_MODAL:
      return {
        ...state,
        show: true,
        data: action.data,
      }
    case type.CLOSE_MODAL:
    return {
        ...state,
        show: false,
        data: [],
    }
    default:
      return state
  }
}
