import React from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { breakPoints, mainColors } from '../../../constants/constants'
import Page from '../../views/Page'
import { Col } from 'react-bootstrap'

import quote from '../../../assets/home/quote-gray.svg'
import background from '../../../assets/home/sky-building.png'

const items = [
  "badge_1",
  "badge_2",
  "badge_3",
  "badge_4",
]

const StyledSection = styled.section`
  background: ${mainColors.lightBlue};
  color: ${mainColors.darkGray};
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: ${breakPoints.sm}) {
    padding-top: 15%;
    padding-bottom: 15%;
  }
`

const StyledPage = styled(Page)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 290px;
  padding-bottom: 290px;

  @media (max-width: ${breakPoints.sm}) {
    padding-top: 12%;
    padding-bottom: 12%;
  }

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    h2 {
      color: ${mainColors.darkGray};
      font-weight: 700;
      font-size: 44px;
      line-height: 56px;
      letter-spacing: 0.2px;
      display: block;
      position: relative;
    }

    h3 {
      color: ${mainColors.darkGray};
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.2px;
      padding: 16px 0;
      font-weight: 400;
    }
  }
`

const LeftCol = styled(Col)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;

  div {
    max-width: 85%;
    width: 100%;
  }

  @media (max-width: ${breakPoints.sm}) {
    max-width: 100%;
    width: 100%;
  }
`

const RightCol = styled(Col)`
  font-style: italic;
  font-weight: 400;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: 0.2px;
  color: ${mainColors.neonBlue};
  position: relative;
  margin-top: 40px;
  padding: 0;

  &:before {
    content: url(${quote});
    top: -55px;
    position: absolute;
    left: 6px;
  }

  @media (max-width: ${breakPoints.sm}) {
    margin-top: 100px;
    width: 100%;
  }
`

const Badge = styled.span`
  font-weight: 700;
  font-size: 18px;
  line-height: 36px;
  letter-spacing: 0.2px;
  color: ${mainColors.darkGray};
  border-radius: 8px;

  @media (max-width: ${breakPoints.sm}) {
    width: 100%;
  }
`

const UnderHero = () => {
  const { t } = useTranslation()

  const badges = (data) => (
    data.map((item, index) => (
      <div key={index}>
        <Badge>{t('home.under_hero.badges.' + item)}</Badge>
      </div>
    ))
  )

  return (
    <StyledSection id="under-hero">
      <StyledPage>
        <LeftCol md={6}>
          <div>
            <h2>{t('home.under_hero.title')}</h2>
            <h3><Trans>{t('home.under_hero.subtitle')}</Trans></h3>
            {badges(items)}
            <h3>{t('home.under_hero.subtitle_2')}</h3>
          </div>
        </LeftCol>
        <RightCol md={6}>
          {t('home.under_hero.quote')}
        </RightCol>
      </StyledPage>
    </StyledSection>
  )
}

export default UnderHero
