import React, { Children, useEffect, useCallback } from 'react'
import styled from 'styled-components'

const StyledCarousel = styled.div`
.carousel-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.carousel-wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.carousel-content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.carousel-content {
  display: flex;
  transition: all 250ms linear;
  -ms-overflow-style: none;
  /* hide scrollbar in IE and Edge */
  scrollbar-width: none;
  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */

.carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-content > * {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
}

.carousel-content.show-2 > * {
  width: 50%;
}

.carousel-content.show-3 > * {
  width: calc(100% / 3);
}

.carousel-content.show-4 > * {
  width: calc(100% / 4);
}

.left-arrow-button, .right-arrow-button {
  display: none !important;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: white;
  border: 1px solid #ddd;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
              rgba(0, 0, 0, 0) 0px 0px 0px 0px,
              rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
              rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  transition: all 150ms linear;
}

.left-arrow-button:hover, .right-arrow-button:hover {
  background-color: #ddd;
}

.left-arrow-button:focus, .right-arrow-button:focus {
  outline: none;
}

.left-arrow-button {
  left: 24px;
}

.right-arrow-button {
  right: 24px;
}

.left-arrow {
  display: block;
  width: 0;
  height: 0;
  border-top: 7.5px solid transparent;
  border-bottom: 7.5px solid transparent;
  border-right:10px solid #484848;
  border-left: 5px solid transparent;
  transform: translateX(-25%);
}

.right-arrow {
  display: block;
  width: 0;
  height: 0;
  border-top: 7.5px solid transparent;
  border-bottom: 7.5px solid transparent;
  border-left:10px solid #484848;
  border-right: 5px solid transparent;
  transform: translateX(25%);
}

@media (hover: none) and (pointer: coarse) {
  .left-arrow-button, .right-arrow-button {
    display: none;
  }
}

.indicator-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  -ms-overflow-style: none;
  /* hide scrollbar in IE and Edge */
  scrollbar-width: none;
  /* hide scrollbar in Firefox */
}

.indicator-container::-webkit-scrollbar, .indicator-container::-webkit-scrollbar {
  display: none;
}

.indicator-container {
  max-width: 56px;
  overflow: auto;
}

.indicator-container > * {
  margin-left: 6px;
  border-radius: 12px;
  transition-property: all;
  transition-duration: 250ms;
  transition-timing-function: linear;
}

.indicator-container > div:first-child {
  margin-left: 0px;
}

.indicator-container > .dots-active {
  width: 12px;
  height: 6px;
  background-color: #00000096;
  flex-shrink: 0;
  flex-grow: 1;
}

.indicator-container > .dots-close {
  width: 6px;
  height: 6px;
  background-color: #00000033;
  flex-shrink: 0;
  flex-grow: 1;
}

.indicator-container > .dots-far {
  width: 4px;
  height: 4px;
  margin-top: 1px;
  margin-bottom: 1px;
  background-color: #00000033;
  flex-shrink: 0;
  flex-grow: 1;
}

`

const ImagesCarousel = ({
  children,
  show,
  infiniteLoop,
  withIndicator,
  renderPreviousButton,
  renderNextButton,
  containerClassName,
  wrapperClassName,
  contentWrapperClassName,
  contentClassName,
  containerProps,
  wrapperProps,
  contentWrapperProps,
  contentProps,
  indicatorContainerClassName,
  indicatorContainerProps,
  indicatorClassNames,
  autoScroll,
}) => {
  const indicatorContainerRef = React.useRef(null)
  const length = React.useMemo(() => Children.count(children), [children])
  const isRepeating = React.useMemo(() => infiniteLoop && Children.count(children) > show, [children, infiniteLoop, show])
  const [currentIndex, setCurrentIndex] = React.useState(isRepeating ? show : 0)
  const [isTransitionEnabled, setTransitionEnabled] = React.useState(true)
  const [touchPosition, setTouchPosition] = React.useState(null)

  useEffect(() => {
    if (isRepeating) {
      if (currentIndex === show || currentIndex === length) {
        setTransitionEnabled(true)
      }
    }
  }, [currentIndex, isRepeating, show, length])

  useEffect(() => {
    if (withIndicator) {
      const active = indicatorContainerRef.current?.querySelector('.dots-active')
      if (active) {
        let index = active.getAttribute('data-index')
        if (index !== null && indicatorContainerRef.current?.scrollTo) {
          indicatorContainerRef.current?.scrollTo({
            left: ((Number(index) - 2) / 5) * 50,
            behavior: 'smooth',
          })
        }
      }
    }
  }, [withIndicator, currentIndex])

  const nextItem = useCallback(
    () => {
      if (isRepeating || currentIndex < (length - show)) {
        setCurrentIndex(prevState => prevState + 1)
      }
    },
    [isRepeating, currentIndex, length, show],
  )

  useEffect(() => {
    if(autoScroll && autoScroll > 0) {
      const interval = setInterval(() => {
        nextItem()
      }, autoScroll)
      return () => clearInterval(interval)
    }
  }, [autoScroll, nextItem])

  const previousItem = () => {
    if (isRepeating || currentIndex > 0) {
      setCurrentIndex(prevState => prevState - 1)
    }
  }

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX
    setTouchPosition(touchDown)
  }

  const handleTouchMove = (e) => {
    const touchDown = touchPosition

    if (touchDown === null) {
      return
    }

    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch

    if (diff > 5) {
      nextItem()
    }

    if (diff < -5) {
      previousItem()
    }

    setTouchPosition(null)
  }

  const handleTransitionEnd = () => {
    if (isRepeating) {
      if (currentIndex === 0) {
        setTransitionEnabled(false)
        setCurrentIndex(length)
      } else if (currentIndex === length + show) {
        setTransitionEnabled(false)
        setCurrentIndex(show)
      }
    }
  }

  const extraPreviousItems = React.useMemo(() => {
    let output = []
    for (let index = 0; index < show; index++) {
      output.push(Children.toArray(children)[length - 1 - index])
    }
    output.reverse()
    return output
  }, [children, length, show])

  const extraNextItems = React.useMemo(() => {
    let output = []
    for (let index = 0; index < show; index++) {
      output.push(Children.toArray(children)[index])
    }
    return output
  }, [children, show])

  const renderDots = React.useMemo(() => {
    let output = []

    const localShow = isRepeating ? show : 0
    const localLength = isRepeating ? length : Math.ceil(length / show)
    const calculatedActiveIndex = (currentIndex - localShow) < 0 ? (length + (currentIndex - localShow)) : currentIndex - localShow

    for (let index = 0; index < localLength; index++) {
      let className = ''
      if (calculatedActiveIndex === index) {
        className = indicatorClassNames?.active || 'dots-active'
      } else {
        if (calculatedActiveIndex === 0) {
          if (calculatedActiveIndex + index <= 2) {
            className = indicatorClassNames?.close || 'dots-close'
          } else {
            className = indicatorClassNames?.far || 'dots-far'
          }
        } else if (calculatedActiveIndex === localLength - 1) {
          if (Math.abs(calculatedActiveIndex - index) <= 2) {
            className = indicatorClassNames?.close || 'dots-close'
          } else {
            className = indicatorClassNames?.far || 'dots-far'
          }
        } else {
          if (Math.abs(calculatedActiveIndex - index) === 1) {
            className = indicatorClassNames?.close || 'dots-close'
          } else {
            className = indicatorClassNames?.far || 'dots-far'
          }
        }
      }
      output.push(
        <div key={index} data-index={index} className={className} />
      )
    }

    return output
  }, [currentIndex, indicatorClassNames, isRepeating, length, show])

  return (
    <StyledCarousel
      data-testid="carousel-container"
      className={`carousel-container ${containerClassName || ''}`}
      {...containerProps}
    >
      <div
        data-testid="carousel-wrapper"
        className={`carousel-wrapper ${wrapperClassName || ''}`}
        {...wrapperProps}
      >
        {
          (isRepeating || currentIndex > 0) ?
            renderPreviousButton ?
            renderPreviousButton(previousItem, 'left-arrow-button')
            :
            <button
              data-testid="left-button"
              onClick={previousItem}
              className="left-arrow-button"
            >
              <span className="left-arrow" />
            </button>
          : null
        }
        <div
          data-testid="carousel-content-wrapper"
          className={`carousel-content-wrapper ${contentWrapperClassName || ''}`}
          {...contentWrapperProps}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <div
            data-testid="carousel-content"
            className={`carousel-content show-${show} ${contentClassName || ''}`}
            {...contentProps}
            style={{
              transform: `translateX(-${currentIndex * (100 / show)}%)`,
              transition: !isTransitionEnabled ? 'none' : undefined,
            }}
            onTransitionEnd={() => handleTransitionEnd()}
          >
            {
              (length > show && isRepeating) &&
              extraPreviousItems
            }
            {children}
            {
              (length > show && isRepeating) &&
              extraNextItems
            }
          </div>
        </div>
        {
          (isRepeating || currentIndex < (length - show)) ?
            renderNextButton ?
            renderNextButton(nextItem, 'right-arrow-button')
            :
            <button
              data-testid="right-button"
              onClick={nextItem}
              className="right-arrow-button"
            >
              <span className="right-arrow" />
            </button>
          : null
        }
      </div>
      {
        withIndicator &&
        <div
          data-testid="indicator-container"
          ref={indicatorContainerRef}
          className={`indicator-container ${indicatorContainerClassName || ''}`}
          {...indicatorContainerProps}
        >
          {renderDots}
        </div>
      }
    </StyledCarousel>
  )
}

export default ImagesCarousel

