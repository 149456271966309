import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { actionIcons, currencySymbols, mainColors, Role, tableKeys } from '../../constants/constants'
import ProjectLayout from './ProjectLayout'
import CustomLinkButton from '../../components/CustomLinkButton'
import { CustomCellWrapper, HeaderWrapper, Loading } from '../../components/sharedStyles'
import CustomTable from '../../components/CustomTable'
import { fetchTableData, toggleActiveRow } from '../../redux/actions/table'
import { getProjectDetails } from '../../redux/actions/projects'
import { checkAccess } from '../../components/helpers'

const createDrawHeaders = data => {
  const draws = data.map(o => o.draw_total_amounts ? o.draw_total_amounts.length : null)
  const mostDraws = Math.max(...draws)
  const drawHeaders = []

  for (let i = 0; i < mostDraws; i++) {
    drawHeaders.push({
      Header: `Draw ${i + 1}`,
      accessor: `draw_total_amounts[${i}].invoices_total_amount`,
      componentType: 'inputNumber',
      disableFilters: true,
      doNotHide: true,
    })
  }

  return drawHeaders
}

const DrawManager = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const addDrawRef = useRef()

  const projectId = location.state?.id
  const details = useSelector(state => state.projects.project_details)
  const loadingDrawManagerData = useSelector(state => state.tables.budget_draw_manager_table.loading)
  const drawManagerData = useSelector(state => state.tables.budget_draw_manager_table.data)
  const activeRowIdDraw = useSelector(state => state.tables.budget_draw_manager_table.active_row)

  useEffect(() => {
    dispatch(fetchTableData(tableKeys.budget_draw_manager_table, projectId))
  }, [dispatch, projectId])

  useEffect(() => {
    if (!currencySymbols[details?.currency]) {
      dispatch(getProjectDetails(projectId))
    }
  }, [dispatch, projectId, details?.currency])

  const drawHeaders = drawManagerData.length > 0 ? createDrawHeaders(drawManagerData) : []

  const COLUMNS_DYNAMIC = [
    {
      Header: 'Budget Line',
      accessor: 'budget_category_id',
      disableFilters: true,
      doNotHide: true,

      Cell: (tableProps) => {
        const index = Number(tableProps.row.id) + 1
        return <CustomCellWrapper>{index}</CustomCellWrapper>
      }
    },
    {
      Header: 'Budget item description',
      accessor: 'label',
      disableFilters: true,
      doNotHide: true,
    },
    {
      Header: 'Cost type',
      accessor: 'type',
      componentType: 'isSelect',
      selectOptions: [
        { id: 'soft', title: t('costs.soft_cost') },
        { id: 'land', title: t('costs.land_cost') },
        { id: 'hard', title: t('costs.hard_cost') },
      ],
      disableFilters: true,
      doNotHide: true,
    },
    {
      Header: 'Budget amount',
      accessor: 'budget_amount',
      componentType: 'inputNumber',
      disableFilters: true,
      doNotHide: true,
    },
    {
      Header: 'Equity',
      accessor: 'initial_contribution',
      componentType: 'inputNumber',
      disableFilters: true,
      doNotHide: true,
    },
    ...drawHeaders,
    {
      Header: 'Remaining value',
      accessor: 'remaining_budget_amount',
      componentType: 'inputNumber',
      disableFilters: true,
      doNotHide: true,
    }
  ]

  return (
    <ProjectLayout
      header={(
        <HeaderWrapper>
          <h2>{t('project.routes.draw_manager')}</h2>
        </HeaderWrapper>
      )}
      footer={
        <>
          <span />
          {checkAccess([Role.admin, Role.monitor]) && (
            <span>
              <span>You have one pending report</span>
              <CustomLinkButton
                type="button"
                onClick={() => console.log('Upload images')}
                textColor={mainColors.white}
                innerColor={mainColors.neonBlue}
                borderColor={mainColors.neonBlue}
                text='Generate Report'
                button
              />
            </span>
          )}
        </>
      }
      footerAlignment="space-between"
    >
      {loadingDrawManagerData ? (
        <Loading />
      ) : (
        <CustomTable
          table_key={tableKeys.budget_draw_manager_table}
          project_id={projectId}
          table_data={drawManagerData.error ? [] : drawManagerData}
          cols={COLUMNS_DYNAMIC}
          showPagination
          toggleActiveRow={toggleActiveRow}
          showStandardColumns={false}
          activeRowId={activeRowIdDraw}
          ref={addDrawRef}
          show_action_items={[]}
          currency={currencySymbols[details?.currency]}
        />
      )}
    </ProjectLayout>
  )
}

export default DrawManager
