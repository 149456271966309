import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector  } from 'react-redux'
import { toggleSidebar } from '../../redux/actions/general'
import { mainColors, menuColors, Role, urls } from '../../constants/constants'
import { NavLink, useMatch } from 'react-router-dom'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { logout_to } from '../../redux/actions/auth'
import Logo from '../../components/Logo'

import { ReactComponent as Home } from '../../assets/icons/home.svg'
import { ReactComponent as Briefcase } from '../../assets/icons/briefcase.svg'
import { ReactComponent as Graph } from '../../assets/icons/graph.svg'
import { ReactComponent as UserCircle } from '../../assets/icons/user_circle_gear.svg'
import { ReactComponent as Logout } from '../../assets/icons/sign_up.svg'
import { ReactComponent as User } from '../../assets/icons/user.svg'
import { ReactComponent as Users } from '../../assets/icons/users.svg'
import { ReactComponent as Bell } from '../../assets/icons/bell.svg'
import { ReactComponent as DoubleArrow } from '../../assets/icons/double_arrow.svg'
import { checkAccess } from '../../components/helpers'

const menuData = [
  {
    to: urls.dashboard,
    title: 'dashboardMenu.dashboard',
    icon: <Home />,
    hasAccess: [Role.admin, Role.monitor, Role.dev]
  },
  {
    to: urls.projects,
    title: 'dashboardMenu.projects',
    icon: <Briefcase />,
    hasAccess: [Role.admin, Role.monitor, Role.dev]
  },
  {
    to: urls.sales,
    title: 'dashboardMenu.sales',
    icon: <Graph />,
    hasAccess: [Role.admin, Role.monitor, Role.dev]
  },
  {
    to: urls.account_settings,
    title: 'dashboardMenu.account_settings',
    icon: <UserCircle />,
    hasAccess: [Role.admin, Role.monitor, Role.dev]
  },
  {
    to: urls.super_user,
    title: 'dashboardMenu.user_management',
    icon: <Users />,
    hasAccess: [Role.admin]
  },
]

const sharedMenuItem = css`
  max-width: 214px;
  width: 100%;
  height: 45px;
  background: transparent;
  border-radius: 8px;
  color: ${menuColors.gray};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  margin-bottom: 19.5px;
  padding-left: 15px;
  font-size: 15px;
  line-height: 36px;

  &:hover,
  &.active {
    background: ${mainColors.darkBlue};
    color: ${mainColors.white};

    svg path {
      stroke: ${mainColors.white};
    }
  }

  svg {
    width: 16.5px;
    height: 17px;
    margin-right: 8px;

    path {
      stroke: ${menuColors.gray};
    }
  }
`

const Main = styled.main`
  margin-top: -80px;
  background: ${mainColors.dashboardBlue};
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${({ $menuWidth }) => $menuWidth}px;
  width: 100%;
  background: ${mainColors.white};
  padding-top: 50px;
  position: fixed;
  bottom: 0;
  top: 0;
  z-index: 10;
`

const StyledNavLink = styled(NavLink)`
  ${sharedMenuItem}

  ${({ $small }) => $small && css`
    width: 45px;
    height: 45px;

    &:hover span {
      position: absolute;
      margin-left: 20px;
      padding-left: 5px;
      background-color: ${mainColors.darkBlue};
      height: inherit;
      display: flex;
      align-items: center;
      border-radius: 0 8px 8px 0;
      width: 140px;
    }

    span {
      display: none;
    }

    svg {
      scale: 1.2;
      height: inherit;
    }
  `}

`

const StyledLogo = styled(Logo)`
  padding-bottom: 43px;
  scale: .8;
`

const SmallLogo = styled(Logo)`
  padding-bottom: 16px;
  scale: 1;
  margin-top: 15px;
  margin-bottom: 30px;
  margin-left: 10px;
`

const StyledHr = styled.hr`
  border: 1px solid ${mainColors.dashboardBlue};
  width: 100%;
  margin: 0;
  margin-bottom: 70px;
`

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  margin-bottom: 36.5px;
  width: 100%;
  flex-direction: column;
`

const StyledButton = styled.button`
  border: none;
  ${sharedMenuItem}
`

const Wrapper = styled.div`
  margin-left: ${({ $menuWidth }) => $menuWidth}px;
  padding: 30px 30px 0px 30px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 67px;

  .btn {
    padding: 0;
    font-size: 0;
    border-radius: 0;
  }

  .btn-primary {
    color: #fff;
    background-color: transparent !important;
    border-color: transparent !important;

    &:hover,
    &:focus {
      background-color: transparent !important;
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }
`

const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -15px;
`

const UserWrapper = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background: ${mainColors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 32px;
`

const ToggleStyledButton = styled(StyledButton)`
  width: 45px;

  svg {
    width: 12px !important;
    height: 12px !important;
    transform: rotate(180deg);
    transition: transform 0.2s ease-in;
  
    &.menu-closed {
      transform: rotate(0deg);
    }
  }
`

const DashLayout = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const match = useMatch(urls.project+'/*')
  const pathname = match?.pattern?.path
  const { children, Component } = props

  const sidebar = useSelector(state => state.general.toggleSidebar)
  const menuWidth = sidebar ? 78 : 282

  const logout = () => {
    dispatch(logout_to())
  }

  const menuItems = menuData.map(link => {
    const activeClass = pathname === urls.project+'/*' && link.to === urls.projects

    if(!checkAccess(link.hasAccess)) return null

    return (
      <StyledNavLink
        key={t(link.title)}
        to={link.to}
        title={t(link.title)}
        className={activeClass ? 'active' : ''}
        $small={sidebar}
      >
        {link.icon}<span>{t(link.title)}</span>
      </StyledNavLink>
    )
  })

  const UserIcon = (
    <UserWrapper>
      <User />
    </UserWrapper>
  )

  return (
    <Main>
      <Menu $menuWidth={menuWidth}>
        {sidebar ? (
          <SmallLogo
            color="min"
            textColor="gray"
            separatorColor="gray"
            to={urls.dashboard}
            showText={false}
          />
        ) : (
          <StyledLogo
            color="blue"
            textColor="gray"
            separatorColor="gray"
            to={urls.dashboard}
          />
        )}

        <StyledHr />
        {menuItems}
        <Bottom>
          {false && (
            <StyledButton onClick={logout}><Logout />{t('logout')}</StyledButton>
          )}
          <StyledHr />
          <ToggleStyledButton onClick={() => dispatch(toggleSidebar())}>
            <DoubleArrow className={sidebar ? 'menu-closed' : 'menu-opened'} />
          </ToggleStyledButton>
        </Bottom>
      </Menu>
      <Wrapper $menuWidth={menuWidth}>
        <Header>
          <Left>
            {Component}
          </Left>
          <Right>
            <Bell />
            <DropdownButton id="dropdown-item-button" title={UserIcon}>
              <Dropdown.Item as="button">Action</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item as="button" onClick={logout}>{t('logout')}</Dropdown.Item>
            </DropdownButton>
          </Right>
        </Header>
        {children}
      </Wrapper>
    </Main>
  )
}

export default DashLayout

DashLayout.propTypes = {
  children: PropTypes.array,
}

DashLayout.default = {
  children: [],
}
