import React from 'react'
import { useSelector } from 'react-redux'
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import { Role, urls } from './constants/constants'

import Home from './pages/Home/Home'
import Benefits from './pages/Benefits'
import SignIn from './pages/SignIn'
import Contact from './pages/Contact'
import AboutUs from './pages/AboutUs/AboutUs'
/*import Terms from './pages/Terms'
import Policies from './pages/Policies'
import Cookies from './pages/Cookies'*/
import Dashboard from './pages/Dashboard'
import Projects from './pages/Projects'
import Project from './pages/Project'
import Sales from './pages/Sales'
import AccountSettings from './pages/AccountSettings'
import SuperUser from './pages/SuperUser'
import NotFound404 from './pages/NotFound404'
import Error503 from './pages/Error503'
import ComingSoon from './pages/ComingSoon'
import Terms from './pages/Terms'
import { checkAccess } from './components/helpers'


export const PrivateRoute = ({ component: PageComponent, isAuth, hasAccess }) => {

  if(!isAuth) {
    return <Navigate push to={urls.sign_in} />
  }

  if (!checkAccess(hasAccess)) {
    // role not authorised so redirect to home page
    return <Navigate to={{ pathname: urls.dashboard }} />
  }

  return <PageComponent />
}

export default function AppRoutes() {
  const isAuth = useSelector(state => state.auth.isAuth)

  return (
    <Routes>
      <Route path={urls.benefits} element={<Benefits />} />
      <Route path={urls.contact} element={<Contact />} />
      <Route path={urls.sign_in} element={<SignIn />} />
      <Route path={urls.home} element={<Home />} />

      <Route
        path={urls.dashboard}
        element={
          <PrivateRoute
            isAuth={isAuth}
            hasAccess={[Role.admin, Role.monitor, Role.dev]}
            component={Dashboard}
          />
        }
      />

      <Route
        path={urls.projects}
        element={
          <PrivateRoute
            isAuth={isAuth}
            hasAccess={[Role.admin, Role.monitor, Role.dev]}
            component={Projects}
          />
        }
      />
      <Route
        path={urls.project+'/*'}
        element={
          <PrivateRoute
            isAuth={isAuth}
            hasAccess={[Role.admin, Role.monitor, Role.dev]}
            component={Project}
          />
        }
      />

      <Route
        path={urls.sales}
        element={
          <PrivateRoute
            isAuth={isAuth}
            hasAccess={[Role.admin, Role.monitor, Role.dev]}
            component={Sales}
          />
        }
      />
      <Route
        path={urls.account_settings}
        element={
          <PrivateRoute
            isAuth={isAuth}
            hasAccess={[Role.admin, Role.monitor, Role.dev]}
            component={AccountSettings}
          />
        }
      />
      <Route
        path={urls.super_user}
        element={
          <PrivateRoute
            isAuth={isAuth}
            hasAccess={[Role.admin]}
            component={SuperUser}
          />
        }
      />

      <Route path={urls.about_us} element={<AboutUs />} />
      <Route path={urls.terms} element={<Terms />} />
      <Route path={urls.privacy} element={<ComingSoon />} />
      <Route path={urls.cookies} element={<ComingSoon />} />

      <Route path="*" element={<NotFound404 />} />
    </Routes>
  )
}

