//AUTHENTICATION
export const AUTH_REQUESTED = 'AUTH_REQUESTED'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILED = 'AUTH_FAILED'
export const AUTH_MANUAL_LOGOUT = 'AUTH_MANUAL_LOGOUT'

//AUTH MODAL
export const OPEN_AUTH_MODAL = 'OPEN_AUTH_MODAL'
export const CLOSE_AUTH_MODAL = 'CLOSE_AUTH_MODAL'

//USER
export const GET_USER_DETAILS_REQUESTED = 'GET_USER_DETAILS_REQUESTED'
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS'
export const GET_USER_DETAILS_FAILED = 'GET_USER_DETAILS_FAILED'
export const UPDATE_USER_DETAILS_REQUESTED = 'UPDATE_USER_DETAILS_REQUESTED'
export const UPDATE_USER_DETAILS_SUCCESS = 'UPDATE_USER_DETAILS_SUCCESS'
export const UPDATE_USER_DETAILS_FAILED = 'UPDATE_USER_DETAILS_FAILED'

//CUSTOM MODAL
export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

//SIDEBAR MENU TOGGLE
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'

//TABLES
export const DELETE_TABLE_DATA_ROW_REQUESTED = 'DELETE_TABLE_DATA_ROW_REQUESTED'
export const DELETE_TABLE_DATA_ROW_SUCCESS = 'DELETE_TABLE_DATA_ROW_REQUESTED'
export const DELETE_TABLE_DATA_ROW_FAILED = 'DELETE_TABLE_DATA_ROW_FAILED'
export const UPDATE_TABLE_DATA_REQUESTED = 'UPDATE_TABLE_DATA_REQUESTED'
export const UPDATE_TABLE_DATA_SUCCESS = 'UPDATE_TABLE_DATA_SUCCESS'
export const UPDATE_TABLE_DATA_FAILED = 'UPDATE_TABLE_DATA_FAILED'
export const ADD_TABLE_HEADER = 'ADD_TABLE_HEADER'
export const REMOVE_TABLE_HEADER = 'REMOVE_TABLE_HEADER'
export const TOGGLE_TABLE_COLUMNS = 'TOGGLE_TABLE_COLUMNS'
export const TOGGLE_ACTIVE_ROW = 'TOGGLE_ACTIVE_ROW'
export const GET_TABLE_DATA_REQUESTED = 'GET_TABLE_DATA_REQUESTED'
export const GET_TABLE_DATA_SUCCESS = 'GET_TABLE_DATA_SUCCESS'
export const GET_TABLE_DATA_FAILED = 'GET_TABLE_DATA_FAILED'

//PROJECTS
export const RESET_PROJECTS_STATE = 'RESET_PROJECTS_STATE'
export const GET_PROJECTS_REQUESTED = 'GET_PROJECTS_REQUESTED'
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS'
export const GET_PROJECTS_FAILED = 'GET_PROJECTS_FAILED'
export const CREATE_PROJECT_REQUESTED = 'CREATE_PROJECT_REQUESTED'
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS'
export const CREATE_PROJECT_FAILED = 'CREATE_PROJECT_FAILED'

//PROJECT
export const GET_PROJECT_DETAILS_REQUESTED = 'GET_PROJECT_DETAILS_REQUESTED'
export const GET_PROJECT_DETAILS_SUCCESS = 'GET_PROJECT_DETAILS_SUCCESS'
export const GET_PROJECT_DETAILS_FAILED = 'GET_PROJECT_DETAILS_FAILED'


