import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Modal } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { closeModal } from '../redux/actions/modal-custom'
import { mainColors } from '../constants/constants'

import { ReactComponent as X } from '../assets/icons/x.svg'
import { sharedInputTextarea, inputDashboard } from './sharedStyles'

const StyledModal = styled(Modal)`
  .table-modal-filter {
    width: 320px;
    border-radius: 16px;
    padding-bottom: 10px !important;

    .modal-title {
      text-align: left !important;
      padding-left: 1rem;
    }
  }

  .modal-dialog {
    max-width: 628px;
    display: flex;
    justify-content: center;

    .modal-content {
      padding: 24px 20px;
  
      .modal-header {
        border-bottom: none;
        padding: 0;
        margin-bottom: 0;
    
        .modal-title {
          position: relative;
          width: 100%;
          text-align: center;
          text-transform: capitalize;
          font-weight: 500;
          font-size: 24px;
          line-height: 42px;
          letter-spacing: -0.02em;
          color: ${mainColors.dashboardDarkBlue};

          svg {
            position: absolute;
            right: 0;
            cursor: pointer;
          }
        }
      }
    
      .modal-footer {
        border-top: none;
      }
    }
  }

  .modal-number-input {
    ${sharedInputTextarea}
    ${inputDashboard}
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  border-radius: 10px;
  margin-top: -24px;
  text-transform: capitalize;
`

const CustomModal = () => {
  const dispatch = useDispatch()
  const close = () => dispatch(closeModal())
  const show = useSelector(state => state.customModal.show)
  const data = useSelector(state => state.customModal.data)
  const { title, body, button, action, showFooter, className, showClose } = data

  return (
    <StyledModal
      show={show}
      onHide={close}
      contentClassName={className}
    >
      <Modal.Header>
        <Modal.Title>
          {title}
          {showClose && <X onClick={close} />}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      {showFooter && (
        <Modal.Footer>
          <StyledButton variant='primary' onClick={action}>
            {button}
          </StyledButton>
        </Modal.Footer>
      )}
    </StyledModal>
  )
}

export default CustomModal

CustomModal.propTypes = {
  title: PropTypes.string,
  body: PropTypes.node,
  button: PropTypes.string,
  action: PropTypes.func,
  showFooter: PropTypes.bool,
  className: PropTypes.string,
  showClose: PropTypes.bool,
}

CustomModal.defaultProps = {
  title: 'Modal',
  body: <div>modal</div>,
  button: 'Save',
  action: () => {},
  showFooter: true,
  className: "",
  showClose: true,
}
